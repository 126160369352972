import moment from 'moment'
export const hoursSum = (array, enableHourValidation = false) => {
    //array = ['7:20', '7:52', '5:03', '1:01', '9:02', '6:00'];
    array = array?.map(arr => `${arr.diff.hour}:${arr.diff.minute}`)

    if (enableHourValidation) {
        array = array?.map(hours => hoursValidate(hours))
    }

    const sum = array?.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());
    if ((sum?.asHours()) || (sum?.minutes())) {
        return [Math.trunc(Math.floor(sum?.asHours())), sum?.minutes()].join(':')
    }
    else return ''
}

export const hoursValidate = (hours) => {
    const hour = Number(hours.split(':')[0])
    if (hour >= 9) {
        return '9:00'
    } else {
        return hours
    }

    // if (hour >= 8) {
    //     return '8:00'
    // } else if (hour >= 5 && hour <= 7) {
    //     return '4:00'
    // } else {
    //     return hours
    // }
}

export const dateDiff = (dateStart, dateEnd) => {
    var d = Math.abs(dateStart - dateEnd.getTime()) / 1000;                 // delta
    var r = {};                                                                // result
    var s = {                                                                  // structure
        // year: 31536000,
        // month: 2592000,
        // week: 604800, // uncomment row to ignore
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
    };

    Object.keys(s).forEach(function (key) {
        r[key] = Math.floor(d / s[key]);
        d -= r[key] * s[key];
    });

    return r;
}
