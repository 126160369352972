
import { createSlice } from "@reduxjs/toolkit";

const timelogs = createSlice({
    name: 'timelogs',
    initialState: {
        getLoading: false,
        getSuccess: false,
        getRejected: false,
        getData: {
            data: []
        },
        postSignLoading: false,
        postSignSuccess: false,
        postSignRejected: false,
        postSignData: {
            message: ""
        }
    },
    reducers: {
        get(state, action) {
            return {
                ...state,
                getLoading: true,
                getSuccess: false,
                getRejected: false
            }
        },
        getFulfilled(state, action) {
            return {
                ...state,
                getLoading: false,
                getSuccess: true,
                getData: action.payload
            }
        },
        getRejected(state, action) {
            return {
                ...state,
                getLoading: false,
                getRejected: true
            }
        },
        postSign(state, action) {
            return {
                ...state,
                postSignLoading: true,
                postSignSuccess: false,
                postSignRejected: false
            }
        },
        postSignFulfilled(state, action) {
            return {
                ...state,
                postSignLoading: false,
                postSignSuccess: true,
                postSignData: action.payload
            }
        },
        postSignRejected(state, action) {
            console.log("action", action)
            return {
                ...state,
                postSignLoading: false,
                postSignRejected: true,
                // postSignData: action.payload
            }
        },
    }
});

export const actions = timelogs.actions;

export default timelogs.reducer;