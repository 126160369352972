import React from 'react'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs, faDatabase } from '@fortawesome/free-solid-svg-icons'
import DatabaseComponent from './database/components'
import SettingsComponent from './settings/components'
import PageLayout from '../shared/pageLayout';

const SuperAdminComponent = () => {
  const paths = [
    {
      label: <h1 className='label-style'>Settings</h1>,
      key: 'Settings',
      route: '/',
      exact: "true",
      component: SettingsComponent,
      icon: <FontAwesomeIcon icon={faCogs} />
    },
    {
      label: <h1 className='label-style'>Database</h1>,
      key: 'Database',
      route: '/database',
      exact: "true",
      component: DatabaseComponent,
      icon: <FontAwesomeIcon icon={faDatabase} />
    },
  ]

  return (
    <PageLayout title='SUPERADMIN PORTAL' paths={paths} sidebarLoading={false} />
  )
}

export default withRouter(SuperAdminComponent)