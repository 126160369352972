import { combineReducers } from 'redux'

// OPEN ROUTE
import login from '../components/login/reducers'
import forgotPassword from '../components/forgotPassword/reducers'
import resetPassword from '../components/resetPassword/reducers'

// Client
import client from '../components/client/reducers'
// Admin
import admin from '../components/admin/reducers'
// SuperAdmin
import superadmin from '../components/superadmin/reducers'

const rootReducer = combineReducers({
  login,
  client,
  admin,
  superadmin,
  forgotPassword,
  resetPassword,
})

export default rootReducer
