import React from 'react'
import { Modal, Tag } from 'antd'
import moment from 'moment'
const OvertimesModal = (props) => {
    return (
        <Modal
            title="Overtimes"
            open={props.visible}
            footer={null}
            onCancel={() => props.onCancel()}
            width={710}
        >
            <table style={{ width: '100%', overflowX: 'scroll' }}>
                <tbody>
                    {
                        props.data && props.data.map((data, i) => {
                            return (
                                <tr key={i}>
                                    <td ><Tag color="green">FROM</Tag>{moment(data.timeIn).format('LLLL')}</td>
                                    <td ><Tag color="gold">TO</Tag> {moment(data.timeOut).format('LLLL')}</td>
                                    <td style={{ color: 'red', fontWeight: 'bold' }}>{data.hours}:00 hrs</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Modal>
    )
}

export default OvertimesModal