
import { createSlice } from "@reduxjs/toolkit";

const login = createSlice({
  name: 'login',
  initialState: {
    loggingIn: false,
    loggedIn: false,
    loginRejected: false,
    loggedData:
    {
      user: {
        username: '',
        email: ''
      }
    },
    getWebsiteSettingsLoading: false,
    getWebsiteSettingsSuccess: false,
    getWebsiteSettingsRejected: false,
    getWebsiteSettingsData: {
      value: {
        logo: '',
        login_bg_image: ''
      }
    },
    postLogoutLoading: false,
    postLogoutSuccess: false,
    postLogoutRejected: false,
    postLogoutData: {}
  },
  reducers: {
    postLoginData(state, action) {
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        loginRejected: false
      }
    },
    postLoginDataFulfilled(state, action) {
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        loggedData: action.payload
      }
    },
    postLoginDataRejected(state, action) {
      return {
        ...state,
        loggingIn: false,
        loginRejected: true
      }
    },

    postLogout(state, action) {
      return {
        ...state,
        postLogoutLoading: true,
        postLogoutSuccess: false,
        postLogoutRejected: false
      }
    },
    postLogoutFulfilled(state, action) {
      return {
        ...state,
        postLogoutLoading: false,
        postLogoutSuccess: true,
        postLogoutData: action.payload
      }
    },
    postLogoutRejected(state, action) {
      return {
        ...state,
        postLogoutLoading: false,
        postLogoutRejected: true
      }
    },

    getWebsiteSettings(state, action) {
      return {
        ...state,
        getWebsiteSettingsLoading: true,
        getWebsiteSettingsSuccess: false,
        getWebsiteSettingsRejected: false
      }
    },
    getWebsiteSettingsFulfilled(state, action) {
      return {
        ...state,
        getWebsiteSettingsLoading: false,
        getWebsiteSettingsSuccess: true,
        getWebsiteSettingsData: action.payload
      }
    },
    getWebsiteSettingsRejected(state, action) {
      return {
        ...state,
        getWebsiteSettingsLoading: false,
        getWebsiteSettingsRejected: true
      }
    },
  }
});

export const actions = login.actions;

export default login.reducer;