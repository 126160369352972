import React from 'react'
import { Collapse, Modal, Tag, Card, Tooltip, Button } from 'antd'
import moment from 'moment'
import { hoursSum, hoursValidate } from '@time-helper'
import Papa from 'papaparse'
import { DownloadOutlined } from '@ant-design/icons';
const { Panel } = Collapse
const OverallModal = (props) => {
    const { data } = props
    const lates = data.timelogs ? data.timelogs.lates : []
    const undertimes = data.timelogs ? data.timelogs.undertimes : []
    const workdays = data.timelogs ? data.timelogs.workdays : []
    const overtimes = data.overtimes ? data.overtimes : []
    const awop = data.awop ? data.awop : []
    const leavesWPay = data.leaves ? data.leaves.filter(leave => leave.isWithPay === 1) : []
    const leaveWOPay = data.leaves ? data.leaves.filter(leave => leave.isWithPay === 0) : []
    const leavesWPayHours = leavesWPay.reduce((accumulator, value) => {
        return accumulator + value.hours;
    }, 0);
    const leavesWOPayHours = leaveWOPay.reduce((accumulator, value) => {
        return accumulator + value.hours;
    }, 0);

    const overtimeHours = overtimes.reduce((accumulator, value) => {
        return accumulator + value.hours;
    }, 0);


    function DownloadWorkdays() {
        const exportData = workdays.map(wd => {
            return {
                "Employee Name": `${data ? data.firstName : ''} ${data ? data.lastName : ''}`,
                "Employee Number": data.employeeNumber,
                "In": wd.start,
                "Out": wd.end,
                "Total": `${hoursValidate(`${wd.diff.hour}:${wd.diff.minute}`)} hrs`
            }
        })
        const unix = Date.now()
        const fileName = `${data ? data.firstName : ''}_${data ? data.lastName : ''}_workdays_${unix}.csv`
        initiateDownload(fileName, exportData)
    }

    function DownloadLates() {
        const exportData = lates.map(late => {
            return {
                "Employee Name": `${data ? data.firstName : ''} ${data ? data.lastName : ''}`,
                "Employee Number": data.employeeNumber,
                "Base": late.base,
                "In": late.timeIn,
                "Total": `${(`${late.diff.hour}:${late.diff.minute}`)} hrs`
            }
        })
        const unix = Date.now()
        const fileName = `${data ? data.firstName : ''}_${data ? data.lastName : ''}_lates_${unix}.csv`
        initiateDownload(fileName, exportData)
    }

    function DownloadUndertimes() {
        const exportData = undertimes.map(undertime => {
            return {
                "Employee Name": `${data ? data.firstName : ''} ${data ? data.lastName : ''}`,
                "Employee Number": data.employeeNumber,
                "In": undertime.timeIn,
                "Out": undertime.timeOut,
                "Total": `${(`${undertime.diff.hour}:${undertime.diff.minute}`)} hrs`
            }
        })
        const unix = Date.now()
        const fileName = `${data ? data.firstName : ''}_${data ? data.lastName : ''}_undertimes_${unix}.csv`
        initiateDownload(fileName, exportData)
    }

    function DownloadLeaveWPay() {
        const exportData = leavesWPay.map(lwp => {
            return {
                "Employee Name": `${data ? data.firstName : ''} ${data ? data.lastName : ''}`,
                "Employee Number": data.employeeNumber,
                "From": moment(lwp.dateFrom).format('LLLL'),
                "To": moment(lwp.dateTo).format('LLLL'),
                "Total": `${lwp.hours}:00 hrs`
            }
        })
        const unix = Date.now()
        const fileName = `${data ? data.firstName : ''}_${data ? data.lastName : ''}_leaveswithpay_${unix}.csv`
        initiateDownload(fileName, exportData)
    }

    function DownloadLeaveWOPay() {
        const exportData = leaveWOPay.map(lwop => {
            return {
                "Employee Name": `${data ? data.firstName : ''} ${data ? data.lastName : ''}`,
                "Employee Number": data.employeeNumber,
                "From": moment(lwop.dateFrom).format('LLLL'),
                "To": moment(lwop.dateTo).format('LLLL'),
                "Total": `${lwop.hours}:00 hrs`
            }
        })
        const unix = Date.now()
        const fileName = `${data ? data.firstName : ''}_${data ? data.lastName : ''}_leaveswithoutpay_${unix}.csv`
        initiateDownload(fileName, exportData)
    }

    function DownloadOvertimes() {
        const exportData = overtimes.map(overtime => {
            return {
                "Employee Name": `${data ? data.firstName : ''} ${data ? data.lastName : ''}`,
                "Employee Number": data.employeeNumber,
                "From": moment(overtime.timeIn).format('LLLL'),
                "To": moment(overtime.timeOut).format('LLLL'),
                "Total": `${overtime.hours}:00 hrs`
            }
        })
        const unix = Date.now()
        const fileName = `${data ? data.firstName : ''}_${data ? data.lastName : ''}_overtimes_${unix}.csv`
        initiateDownload(fileName, exportData)
    }

    function DownloadAbsents() {
        const exportData = awop.map(absent => {
            return {
                "Employee Name": `${data ? data.firstName : ''} ${data ? data.lastName : ''}`,
                "Employee Number": data.employeeNumber,
                "From": absent
            }
        })
        const unix = Date.now()
        const fileName = `${data ? data.firstName : ''}_${data ? data.lastName : ''}_absents_${unix}.csv`
        initiateDownload(fileName, exportData)
    }

    function initiateDownload(fileName, data) {
        var csv = Papa.unparse(data);
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, fileName);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', fileName);
        tempLink.click();
    }

    return (
        <Modal
            destroyOnClose
            title={`${data.firstName} ${data.lastName}'s DTR Record`}
            open={props.visible}
            footer={null}
            onCancel={() => props.onCancel()}
            width={710}
        >
            <Card style={{ marginBottom: 12 }}>
                <h3><a href={() => false}>{data.firstName} {data.lastName} (#{data.employeeNumber})</a></h3>
                <p><a href={() => false}>{data.workingDays && data.workingDays.toString()} <br /> ({data.shiftIn} → {data.shiftOut})</a></p>
            </Card>
            <Collapse>
                <Panel header={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4>Workdays(in hrs) </h4>
                        <b style={{ color: 'red' }}>TOTAL : {hoursSum(workdays, true) || 0} {hoursSum(workdays, true) <= 0 ? 'hr' : 'hrs'}</b>
                    </div>
                } key="1">
                    <Tooltip title="Download CSV"><Button onClick={() => DownloadWorkdays()} className='btn-circle' shape="circle" icon={<DownloadOutlined />} /></Tooltip>
                    <table style={{ width: '100%', overflowX: 'scroll' }}>
                        <tbody>
                            {
                                workdays ? workdays.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><Tag color="green">IN</Tag>{data.start}</td>
                                            <td><Tag color="gold">OUT</Tag>{data.end}</td>
                                            <td style={{ color: 'red', fontWeight: 'bold' }}>{hoursValidate(`${data.diff.hour}:${data.diff.minute}`)} hrs</td>
                                        </tr>
                                    )
                                }) : <tr><td>No Records</td></tr>
                            }
                        </tbody>

                    </table>
                </Panel>
                <Panel header={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4>Lates </h4>
                        <b style={{ color: 'red' }}>TOTAL : {hoursSum(lates) || 0} {hoursSum(lates) <= 0 ? 'hr' : 'hrs'}</b>
                    </div>
                } key="2">
                    <Tooltip title="Download CSV"><Button onClick={() => DownloadLates()} className='btn-circle' shape="circle" icon={<DownloadOutlined />} /></Tooltip>
                    <table style={{ width: '100%', overflowX: 'scroll' }}>
                        <tbody>
                            {
                                lates ? lates.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td ><Tag color="green">BASE</Tag>{data.base}</td>
                                            <td ><Tag color="gold">IN</Tag> {data.timeIn}</td>
                                            <td style={{ color: 'red', fontWeight: 'bold' }}>{data.diff.hour}:{data.diff.minute} hrs</td>
                                        </tr>
                                    )
                                }) : <tr><td>No Records</td></tr>
                            }
                        </tbody>
                    </table>
                </Panel>
                <Panel header={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4>Undertimes </h4>
                        <b style={{ color: 'red' }}>TOTAL : {hoursSum(undertimes) || 0} {hoursSum(undertimes) <= 0 ? 'hr' : 'hrs'}</b>
                    </div>} key="3">
                    <Tooltip title="Download CSV"><Button onClick={() => DownloadUndertimes()} className='btn-circle' shape="circle" icon={<DownloadOutlined />} /></Tooltip>
                    <table style={{ width: '100%', overflowX: 'scroll' }}>
                        <tbody>
                            {
                                undertimes ? undertimes.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><Tag color="green">IN</Tag>{data.timeIn}</td>
                                            <td><Tag color="gold">OUT</Tag>{data.timeOut}</td>
                                            <td style={{ color: 'red', fontWeight: 'bold' }}>{data.diff.hour}:{data.diff.minute} hrs</td>
                                        </tr>
                                    )
                                }) : <tr><td>No Records</td></tr>
                            }
                        </tbody>
                    </table>
                </Panel>
                <Panel header={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4>Leaves With Pay </h4>
                        <b style={{ color: 'red' }}>TOTAL :  {leavesWPayHours || 0}:00 Hrs</b>
                    </div>
                } key="4">
                    <Tooltip title="Download CSV"><Button onClick={() => DownloadLeaveWPay()} className='btn-circle' shape="circle" icon={<DownloadOutlined />} /></Tooltip>
                    <table style={{ width: '100%', overflowX: 'scroll' }}>
                        <tbody>
                            {
                                leavesWPay.length ? leavesWPay.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><a href={() => false}>{data.reason}</a></td>
                                            <td><Tag color="green">FROM</Tag>{moment(data.dateFrom).format('LLLL')}</td>
                                            <td><Tag color="gold">TO</Tag>{moment(data.dateTo).format('LLLL')}</td>
                                            <td style={{ color: 'red', fontWeight: 'bold' }}>{data.hours}:00 hrs</td>
                                        </tr>
                                    )
                                }) : <tr><td>No Records</td></tr>
                            }
                        </tbody>
                    </table>
                </Panel>
                <Panel header={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4>Leaves Without Pay </h4>
                        <b style={{ color: 'red' }}>TOTAL :  {leavesWOPayHours || 0}:00 Hrs</b>
                    </div>
                } key="5">
                    <Tooltip title="Download CSV"><Button onClick={() => DownloadLeaveWOPay()} className='btn-circle' shape="circle" icon={<DownloadOutlined />} /></Tooltip>
                    <table style={{ width: '100%', overflowX: 'scroll' }}>
                        <tbody>
                            {
                                leaveWOPay.length ? leaveWOPay.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><a href={() => false}>{data.reason}</a></td>
                                            <td><Tag color="green">FROM</Tag>{moment(data.dateFrom).format('LLLL')}</td>
                                            <td><Tag color="gold">TO</Tag>{moment(data.dateTo).format('LLLL')}</td>
                                            <td style={{ color: 'red', fontWeight: 'bold' }}>{data.hours}:00 hrs</td>
                                        </tr>
                                    )
                                }) : <tr><td>No Records</td></tr>
                            }
                        </tbody>
                    </table>
                </Panel>
                <Panel header={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4>Overtimes</h4>
                        <b style={{ color: 'red' }}>TOTAL :  {overtimeHours || 0}:00 Hrs</b>
                    </div>
                } key="6">
                    <Tooltip title="Download CSV"><Button onClick={() => DownloadOvertimes()} className='btn-circle' shape="circle" icon={<DownloadOutlined />} /></Tooltip>
                    <table style={{ width: '100%', overflowX: 'scroll' }}>
                        <tbody>
                            {
                                overtimes.length ? overtimes.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><a href={() => false}>{data.reason}</a></td>
                                            <td><Tag color="green">FROM</Tag>{moment(data.timeIn).format('LLLL')}</td>
                                            <td><Tag color="gold">TO</Tag>{moment(data.timeOut).format('LLLL')}</td>
                                            <td style={{ color: 'red', fontWeight: 'bold' }}>{data.hours}:00 hrs</td>
                                        </tr>
                                    )
                                }) : <tr><td>No Records</td></tr>
                            }
                        </tbody>
                    </table>
                </Panel>
                <Panel header={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4>Absent Without Pay (AWOP)</h4>
                        <b style={{ color: 'red' }}>TOTAL : {awop.length}</b>
                    </div>
                } key="7">
                    <Tooltip title="Download CSV"><Button onClick={() => DownloadAbsents()} className='btn-circle' shape="circle" icon={<DownloadOutlined />} /></Tooltip>
                    <table style={{ width: '100%', overflowX: 'scroll' }}>
                        <tbody>
                            {
                                awop.length ? awop.map((date, i) => {
                                    return (
                                        <tr key={i}>
                                            <td ><Tag color="gold">Absent: </Tag>{date}</td>
                                        </tr>
                                    )
                                }) : <tr><td>No Records</td></tr>
                            }
                        </tbody>
                    </table>
                </Panel>
            </Collapse>
        </Modal>
    )
}

export default OverallModal