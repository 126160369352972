import React, { useState } from 'react'
import { Button, Row, Col, Modal, Card, message, Spin, Checkbox } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { resetDatabase } from '../actions'
// import session from '../../../../utils/session';
import './index.scss'
const DatabaseComponent = (props) => {
    const [loading, setLoading] = useState(false)
    const tables = [
        'accountabilities',
        'allowance_allotments',
        'allowance_types',
        'announcements',
        'audits',
        'business_trips',
        'deductions',
        'deduction_types',
        'dependents',
        'disciplinary_actions',
        'disciplinary_action_types',
        'documents',
        'educations',
        'employment_histories',
        'file_reports',
        'leaves',
        'leave_types',
        'medical_histories',
        'memorandums',
        'performances',
        'positions',
        'projects',
        'references',
        'suppliers',
        'time_requests',
        'users',
    ]
    const [selectedTables, setSelectedTables] = useState([])

    function handleResetDatabase() {
        Modal.confirm({
            title: 'Do you Want to reset your database?',
            icon: <ExclamationCircleOutlined />,
            content: 'You cannot undo this.',
            onOk() {
                setLoading(true)
                props.onResetDatabase(selectedTables).then((res) => {
                    if (res.type === 'database/resetFulfilled') {
                        // session.logout()
                    } else {
                        message.error('Unable To Reset Database. Please try again later');
                    }
                    setLoading(false)
                })
            },
            onCancel() { },
        });
    }

    function onChangeCheckbox(values) {
        setSelectedTables([...values])
    }

    return (
        <Spin spinning={loading}>
            <Card className='admin-database-settings-container' bordered={false} >
                <Row>
                    <Col span={24}>
                        <div className='warning-message'>
                            <h5>Clicking truncate button will reset all your tables and also data will be deleted.</h5>
                            <h5>Make sure you have downloaded or exported your database for back up.</h5>
                            <h5>You cannot undo the process once you confirm.</h5>
                        </div>
                        <div className='warning-message-logout'>
                            <h5>After truncating your tables you will be redirected to logout. </h5>
                            <h5>and can login again with the credentials given by the system administrator.</h5>
                        </div>

                        <div className='warning-message-logout'>
                            <h5>Selected Tables. </h5>
                        </div>
                        <div className='table-selections'>
                            <Checkbox.Group style={{ width: '100%' }}
                                defaultValue={tables}
                                onChange={onChangeCheckbox}
                            >
                                <Row>
                                    {
                                        tables.map((data, index) => {
                                            return (
                                                <Col span={24} key={index}>
                                                    <Checkbox value={data}>{data.toUpperCase()}</Checkbox>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Checkbox.Group>
                        </div>
                        <Button onClick={() => handleResetDatabase()} type="text" danger>Reset Database (Truncate Tables)</Button>
                    </Col>
                </Row>
            </Card>
        </Spin>
    )
}


function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        onResetDatabase: (tables) => dispatch(resetDatabase(tables)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatabaseComponent))
