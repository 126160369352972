import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Row, Col, Tag, Table, Avatar, Card } from 'antd'
import { loadTimelogs } from '../actions'
import './index.scss'
import { ImagePresignedUrl } from '@renderer'
import moment from 'moment-business-time'
import Toolbar from '@toolbar'
import Permissions from '@permissions'
import { serviceStatusTag } from '../../shared/components/serviceStatusTag'
const AllRecords = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        employeeNumber: '',
        startDate: null,
        endDate: null
    })
    const [employeeNumber, setEmployeeNumber] = useState('')

    const { getData, getLoading, } = useSelector((state) => state.admin.timelogs)
    const dispatch = useDispatch()

    useEffect(() => {
        if (pagination.startDate && pagination.endDate) {
            dispatch(loadTimelogs(pagination))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    useEffect(() => {
        setTableData([...getData.data])
        return () => { }
    }, [getData])

    useEffect(() => {
        if (employeeNumber) {
            const delayDebounceFn = setTimeout(() => {
                setPagination(prevState => ({ ...prevState, employeeNumber }));
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        } else {
            dispatch(loadTimelogs({ ...pagination, employeeNumber: '' }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeeNumber])

    function typeTag(type) {
        switch (type) {
            case 'IN':
                return <Tag color="green">IN</Tag>
            case 'OUT':
                return <Tag color="geekblue">OUT</Tag>
            case 'BREAK OUT':
                return <Tag color="gold">BREAK OUT</Tag>
            default:
                return;
        }
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={44} src={<ImagePresignedUrl src={data && data.employee && data.employee.image && data.employee.image[0] && data.employee.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Employee Number',
            dataIndex: ['employee', 'employeeNumber'],
            key: 'employeeNumber',
            responsive: ['lg', 'md', 'sm'],
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{data && data.employee && data.employee.firstName} {data && data.employee && data.employee.lastName}</span>)
            }
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            render: (item, data) => {
                return (
                    <Fragment>
                        <span style={{ fontWeight: 'bold', color: '#4A9EF7', fontSize: 14 }}>{moment(item).format('LLLL')}</span><br />
                        <span>{data && data.location}</span>
                    </Fragment>
                )
            }
        },
        {
            title: 'Data Source',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            render: (item, data) => {
                return (
                    <span>{item ? 'BIOMETRICS' : 'WEB APP'}</span>
                )
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            responsive: ['lg', 'md', 'sm'],
            render: (item) => {
                return (
                    <div>
                        {typeTag(item)}
                    </div>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (item) => {
                return (<span>{serviceStatusTag(item)}</span>)
            }
        },
    ];
    return (
        <Layout>
            <Permissions module='timelogs' role='view' >
                <Row gutter={32}>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <Toolbar
                            title='Timelogs'
                            handleSearch={(employeeNumber) => setEmployeeNumber(employeeNumber)}
                            handleDateRange={(dateRange) => setPagination({ ...pagination, startDate: dateRange[0], endDate: dateRange[1] })}
                        />
                        <Table columns={columns} dataSource={dataSource} style={{ overflowX: 'scroll' }} rowKey={obj => obj._id} loading={getLoading} />
                    </Col>
                </Row>
            </Permissions>

        </Layout>
    )
}

export default AllRecords