import { combineReducers } from 'redux'
import database from '../database/reducers'
import settings from '../settings/reducers'

const rootReducer = combineReducers({
  database,
  settings
})

export default rootReducer
