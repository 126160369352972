import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Modal, notification, DatePicker, Select, Divider, Card } from 'antd'
import { update, create, loadAllowanceHistoryTypes, loadSupplierTin } from '../actions'
import { ADD, VIEW, NONE } from '@page-actions'
import session from '@session'
import './index.scss'
import moment from 'moment'

const { Option } = Select

const FormAllowance = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const pagination = {
        page: 1,
        limit: 10,
        status: 1,
        sort: 'desc',
        search: '',
    }
    let setSelectedSupplier = {}
    const [tinValue, setTinValue] = useState('')
    const [supplierData, setSupplierData] = useState([])
    const [showSupplierDataItems, setShowSupplierDataItems] = useState(false)
    const [supplierLoading, setSupplierLoading] = useState(false)
    const [supplierDisabled, setSupplierDisabled] = useState(false)
    const dispatch = useDispatch();
    const { updateLoading, getHistoryTypesData } = useSelector(state => state.client.allowances)
    const employees = useSelector(state => state.client)

    useEffect(() => {
        const subTotalAmount = (+(props.selectedRow && props.selectedRow.vatableAmount) || 0) + (+(props.selectedRow && props.selectedRow.vatableExemptAmount) || 0)
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            date: (props.selectedRow && moment(props.selectedRow.date)) || new Date(),
            orDate: (props.selectedRow && moment(props.selectedRow.orDate)) || new Date(),
            applicationMonth: (props.selectedRow && moment(props.selectedRow.applicationMonth)) || new Date(),
            supplierName: props.selectedRow && props.selectedRow.supplier && props.selectedRow.supplier.name,
            supplierAddress: props.selectedRow && props.selectedRow.supplier && props.selectedRow.supplier.address,
            subTotalAmount: subTotalAmount
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    async function handleSubmit(value) {
        setLoading(true)

        const submitData = { ...value, }

        if (props.actionType === ADD) {
            const userId = session.user().id
            dispatch(create({ userId, ...submitData })).then(res => {
                if (res.type === 'allowances/createAllowanceFulfilled') {
                    form.resetFields()
                    props.setActionType(NONE)
                    notification.success({
                        message: `Added`,
                        description: 'You have successfully added new allowances information.',
                    });
                }
                setLoading(false)
            }).finally(() => {
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const allowanceId = props.selectedRow._id
            dispatch(update({ id: allowanceId, ...submitData })).then(res => {
                if (res.type === 'allowances/updateAllowanceFulfilled') {
                    form.resetFields()
                    notification.success({
                        message: `Updated`,
                        description: 'You have successfully updated the allowances information.',
                    });
                }
                setLoading(false)
            }).finally(() => {
                setLoading(false)

            })
        }
    }

    useEffect(() => {
        dispatch(loadAllowanceHistoryTypes({
            page: 1,
            limit: 100,
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    console.log(search)
    useEffect(() => {
        if (!tinValue.length) return
        const delayDebounceFn = setTimeout(() => {
            dispatch(loadSupplierTin({ ...pagination, search: tinValue })).then(res => {
                setSupplierLoading(true)
                setSupplierData(res.payload.data.data)
                const foundTin = res.payload.data.find(data => data.tin === tinValue)
                if (foundTin) {
                    form.setFieldsValue({
                        supplierName: foundTin.name,
                        supplierAddress: foundTin.address
                    })
                    setSupplierDisabled(true)
                    setShowSupplierDataItems(true)
                } else {
                    form.setFieldsValue({
                        supplierName: '',
                        supplierAddress: ''
                    })
                    setSupplierDisabled(false)
                }
            }).finally(() => {
                setSupplierLoading(false)
            })

        }, 700)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tinValue])

    function handleSupplierSelect(data) {
        setSupplierData([])
        setSelectedSupplier(data)
        setSupplierDisabled(true)
        form.setFieldsValue({
            tin: data.tin,
            supplierName: data.name,
            supplierAddress: data.address
        })
    }

    function onBlurSupplier(value) {
        var container = document.getElementsByClassName('list')[0];
        document.addEventListener('click', function (event) {
            if (container !== event.target && !container.contains(event.target)) {
                setShowSupplierDataItems(false)
            }
        });
    }

    function onChangeInAmountFields() {
        const vatableAmount = form.getFieldValue('vatableAmount') || 0
        const vatableExemptAmount = form.getFieldValue('vatableExemptAmount') || 0
        const subTotalAmount = (+vatableAmount || 0) + (+vatableExemptAmount || 0)
        const vat = (+vatableAmount) * .12
        form.setFieldsValue({
            subTotalAmount: subTotalAmount,
            vat: vat,
            totalAmount: subTotalAmount + vat
        })
    }

    return (
        <Modal forceRender title={(props.selectedRow && props.selectedRow.company) || 'Allowance'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`allowance`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={24} lg={24} sm={24} xs={24}>
                            <Form.Item
                                label="Description"
                                name="allowanceTypeId"
                                rules={[{ required: true, message: 'Please input description!' }]}
                            >
                                <Select placeholder='Search Allowance Type' style={{ width: '100%' }} defaultValue={props.selectedRow && props.selectedRow.allowanceTypeId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={employees.getLoading}  >
                                    {
                                        Array.isArray(getHistoryTypesData) && getHistoryTypesData.map((data, index) => {
                                            return (
                                                <Option key={data._id} value={data._id} data={data} >{data.title} </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="OR No."
                                name="orNumber"
                                rules={[{ required: true, message: 'Please input OR No.!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="OR Date"
                                name="orDate"
                                rules={[{ required: true, message: 'Please input OR date!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Transaction Date"
                                name="date"
                                rules={[{ required: true, message: 'Please input date!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Application Month"
                                name="applicationMonth"
                                rules={[{ required: true, message: 'Please input application month!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="MMMM"
                                    style={{ width: '100%' }}
                                    picker="month"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <h1>Amount / Tax</h1>
                    <Row gutter={12}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Vatable Amount"
                                name="vatableAmount"
                                rules={[{ required: true, message: 'Please input vatable amount!' }]}
                            >
                                <Input type='number' onChange={onChangeInAmountFields} />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Vatable Exempt"
                                name="vatableExemptAmount"
                                rules={[{ required: true, message: 'Please input vatable exempt amount!' }]}
                            >
                                <Input type='number' onChange={onChangeInAmountFields} />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Sub Total Amount"
                                name="subTotalAmount"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="VAT (12%)"
                                name="vat"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Total Amount"
                                name="totalAmount"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <h1>Supplier</h1>
                    <Row gutter={12}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <div className='supplier-select'>
                                <Form.Item
                                    label="Suppier TIN"
                                    name="tin"
                                    rules={[{ required: true, message: 'Please input tin!' }]}
                                >
                                    <Input onChange={(e) => setTinValue(e.target.value)} autoComplete="off" onBlur={(e) => onBlurSupplier(e.target.value)} />
                                </Form.Item>
                                <div className='list'>
                                    {
                                        (showSupplierDataItems && supplierData && supplierData.length) ?
                                            <Card>
                                                {
                                                    supplierData.map((data, index) => {
                                                        return <h4 onClick={() => handleSupplierSelect(data)} key={index}>{data.tin}</h4>
                                                    })
                                                }
                                            </Card> : null
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Card className='supplier-card' bordered={false} loading={supplierLoading}>
                        <Row gutter={12}>

                            <Col md={12} lg={12} sm={24} xs={24}>
                                <Form.Item
                                    label="Supplier Name"
                                    name="supplierName"
                                    rules={[{ required: true, message: 'Please input supplier name!' }]}
                                >
                                    <Input disabled={supplierDisabled} />
                                </Form.Item>
                            </Col>
                            <Col md={12} lg={12} sm={24} xs={24}>
                                <Form.Item
                                    label="Supplier Address"
                                    name="supplierAddress"
                                    rules={[{ required: true, message: 'Please input supplier address!' }]}
                                >
                                    <Input disabled={supplierDisabled} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>


                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={updateLoading || loading}   >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}





export default FormAllowance