
import { createSlice } from "@reduxjs/toolkit";

const disciplinaryActions = createSlice({
  name: 'leaves',
  initialState: {
    getLoading: false,
    getSuccess: false,
    getRejected: false,
    getData: {
      data: []
    },
    getByIdLoading: false,
    getByIdSuccess: false,
    getByIdRejected: false,
    getByIdData: {},
    updateLoading: false,
    updateSuccess: false,
    updateRejected: false,
    createLoading: false,
    createSuccess: false,
    createRejected: false,
    removeLoading: false,
    removeSuccess: false,
    removeRejected: false,

    getTypeLoading: false,
    getTypeSuccess: false,
    getTypeRejected: false,
    getTypeData: {
      data: []
    },
    getTypeByIdLoading: false,
    getTypeByIdSuccess: false,
    getTypeByIdRejected: false,
    getTypeByIdData: {},
    updateTypeLoading: false,
    updateTypeSuccess: false,
    updateTypeRejected: false,
    createTypeLoading: false,
    createTypeSuccess: false,
    createTypeRejected: false,
    removeTypeLoading: false,
    removeTypeSuccess: false,
    removeTypeRejected: false,
  },
  reducers: {
    get(state, action) {
      return {
        ...state,
        getLoading: true,
        getSuccess: false,
        getRejected: false,
        getByIdData: {}
      }
    },
    getFulfilled(state, action) {
      return {
        ...state,
        getLoading: false,
        getSuccess: true,
        getData: action.payload
      }
    },
    getRejected(state, action) {
      return {
        ...state,
        getLoading: false,
        getRejected: true
      }
    },
    getById(state, action) {
      return {
        ...state,
        getByIdLoading: true,
        getByIdSuccess: false,
        getByIdRejected: false,
      }
    },
    getByIdFulfilled(state, action) {
      return {
        ...state,
        getByIdLoading: false,
        getByIdSuccess: true,
        getByIdData: action.payload
      }
    },
    getByIdRejected(state, action) {
      return {
        ...state,
        getByIdLoading: false,
        getByIdRejected: true
      }
    },
    create(state, action) {
      return {
        ...state,
        createLoading: true,
        createSuccess: false,
        createRejected: false
      }
    },
    createFulfilled(state, action) {
      return {
        ...state,
        createLoading: false,
        createSuccess: true,
        createData: action.payload
      }
    },
    createRejected(state, action) {
      return {
        ...state,
        createLoading: false,
        createRejected: true
      }
    },
    update(state, action) {
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
        updateRejected: false
      }
    },
    updateFulfilled(state, action) {
      return {
        ...state,
        updateLoading: false,
        updateSuccess: true,
        updateData: action.payload
      }
    },
    updateRejected(state, action) {
      return {
        ...state,
        updateLoading: false,
        updateRejected: true
      }
    },
    remove(state, action) {
      return {
        ...state,
        removeLoading: true,
        removeSuccess: false,
        removeRejected: false
      }
    },
    removeFulfilled(state, action) {
      return {
        ...state,
        removeLoading: false,
        removeSuccess: true,
        removeData: action.payload
      }
    },
    removeRejected(state, action) {
      return {
        ...state,
        removeLoading: false,
        removeRejected: true
      }
    },
    getType(state, action) {
      return {
        ...state,
        getTypeLoading: true,
        getTypeSuccess: false,
        getTypeRejected: false
      }
    },
    getTypeFulfilled(state, action) {
      return {
        ...state,
        getTypeLoading: false,
        getTypeSuccess: true,
        getTypeData: action.payload
      }
    },
    getTypeRejected(state, action) {
      return {
        ...state,
        getTypeLoading: false,
        getTypeRejected: true
      }
    },
    getTypeById(state, action) {
      return {
        ...state,
        getTypeByIdLoading: true,
        getTypeByIdSuccess: false,
        getTypeByIdRejected: false
      }
    },
    getTypeByIdFulfilled(state, action) {
      return {
        ...state,
        getTypeByIdLoading: false,
        getTypeByIdSuccess: true,
        getTypeByIdData: action.payload
      }
    },
    getTypeByIdRejected(state, action) {
      return {
        ...state,
        getTypeByIdLoading: false,
        getTypeByIdRejected: true
      }
    },
    createType(state, action) {
      return {
        ...state,
        createTypeLoading: true,
        createTypeSuccess: false,
        createTypeRejected: false
      }
    },
    createTypeFulfilled(state, action) {
      return {
        ...state,
        createTypeLoading: false,
        createTypeSuccess: true,
        createTypeData: action.payload
      }
    },
    createTypeRejected(state, action) {
      return {
        ...state,
        createTypeLoading: false,
        createTypeRejected: true
      }
    },
    updateType(state, action) {
      return {
        ...state,
        updateTypeLoading: true,
        updateTypeSuccess: false,
        updateTypeRejected: false
      }
    },
    updateTypeFulfilled(state, action) {
      return {
        ...state,
        updateTypeLoading: false,
        updateTypeSuccess: true,
        updateTypeData: action.payload
      }
    },
    updateTypeRejected(state, action) {
      return {
        ...state,
        updateTypeLoading: false,
        updateTypeRejected: true
      }
    },
    removeType(state, action) {
      return {
        ...state,
        removeTypeLoading: true,
        removeTypeSuccess: false,
        removeTypeRejected: false
      }
    },
    removeTypeFulfilled(state, action) {
      return {
        ...state,
        removeTypeLoading: false,
        removeTypeSuccess: true,
        removeTypeData: action.payload
      }
    },
    removeTypeRejected(state, action) {
      return {
        ...state,
        removeTypeLoading: false,
        removeTypeRejected: true
      }
    }
  }
});

export const actions = disciplinaryActions.actions;

export default disciplinaryActions.reducer;