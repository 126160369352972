import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Layout, Form, Modal } from 'antd'
import { update, create } from '../actions'
import { ADD, VIEW, NONE } from '@page-actions'
import session from '@session'
import './index.scss'
import moment from 'moment'

const FormPerformance = (props) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch();

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            year: (props.selectedRow && moment(props.selectedRow.year)) || new Date(),
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    async function handleSubmit(value) {
        const submitData = { ...value, }

        if (props.actionType === ADD) {
            const userId = session.user().id
            dispatch(create({ userId, ...submitData })).then(res => {
                props.setActionType(NONE)
                form.resetFields()
            })
        }

        if (props.actionType === VIEW) {
            const performanceId = props.selectedRow._id
            dispatch(update({ id: performanceId, ...submitData })).then(res => {
                form.resetFields()
            })
        }
    }

    return (
        <Modal forceRender title={(props.selectedRow && props.selectedRow.company) || 'Add Performance'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`performance`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                </Form>
            </Layout>
        </Modal>
    )
}

export default FormPerformance