import React from 'react'
import PageComponent from './list'
import FormComponent from './form'
import UploadComponent from './upload'
import { Route, BrowserRouter, Switch, useRouteMatch } from 'react-router-dom';
import './index.scss'

const Index = (props) => {
    let { path } = useRouteMatch();
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={path} component={PageComponent} />
                <Route path={`${path}/create`} component={FormComponent} />
                <Route path={`${path}/upload`} component={UploadComponent} />
                <Route path={`${path}/view/:id`} component={FormComponent} />
            </Switch>
        </BrowserRouter>
    )
}

export default Index