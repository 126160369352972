import React, { useEffect, useState } from 'react'
import { Row, Col, Pagination, Collapse, Card, Table, Modal, Button, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { load } from '../actions'
import { IframePresignedUrl } from '@renderer'
import _ from 'lodash'
import Toolbar from '@toolbar'
import './index.scss'
import { ImagePresignedUrl } from '@renderer'
import draftToHtml from 'draftjs-to-html';
import session from '@session'

const { Panel } = Collapse;
const MemorandumComponents = () => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [modalViewShow, setModalViewShow] = useState(false)
    const [selectedRow, setSelectedRow] = useState({})
    const dispatch = useDispatch();
    const { getData, getLoading } = useSelector(state => state.client.memorandums)

    useEffect(() => {
        dispatch(load(pagination))
        return () => { }
        // eslint-disable-next-line
    }, [])

    function onChangePagination(page, limit) {
        const newPagination = {
            page: page,
            limit: limit,
            search: pagination.search
        }
        dispatch(load(newPagination))
        setPagination(prevState => ({
            ...prevState,
            page, limit
        }));
    }

    async function handleRowClick(e, data) {
        setModalViewShow(true)
        setSelectedRow(data)

        const userId = session.user().id
        const submitData = {
            viewerId: userId,
            docId: data._id
        }
        console.log("submitData", submitData)
        // props.onViewerAnnouncement({ ...submitData })
    }

    return (
        <div className='page-content'>
            <Card bordered={false}>
                <Toolbar
                    title='Memorandums'
                    handleSearch={(search) => setPagination({ ...pagination, search })}
                />
                <Table columns={[
                    {
                        title: 'Title',
                        dataIndex: 'title',
                        key: 'title'
                    },
                    {
                        title: 'Memorandum Number',
                        dataIndex: 'memoNumber',
                        key: 'memoNumber'
                    },
                    {
                        title: 'Images / Files',
                        dataIndex: 'images',
                        key: 'images',
                        responsive: ['lg', 'md', 'sm'],
                        render: (item, data) => {
                            return <div className='files'>
                                {
                                    data.files && data.files.map((item, index) => {
                                        return (
                                            <Panel header={_.startCase(item.path)} key={index} style={{ backgroundColor: 'white' }}>
                                                <IframePresignedUrl src={item.url} style={{ width: '100%', height: 740 }} /><br />
                                            </Panel>
                                        )
                                    })
                                }
                            </div>
                        }
                    },
                    {
                        title: 'Actions',
                        dataIndex: 'actions',
                        key: 'actions',
                        render: (item, data) => {
                            return (
                                <Space>
                                    <Button type='primary' size='small' onClick={(e) => {
                                        handleRowClick(e, data)
                                    }}  >
                                        View
                                    </Button>
                                </Space>
                            )
                        }
                    },
                ]} dataSource={getData.data} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />

            </Card>
            <Row style={{ marginTop: 30, width: '100%' }}>
                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                </Col>
            </Row>
            <Modal forceRender title={selectedRow.title} visible={modalViewShow} footer={null} onCancel={() => setModalViewShow(false)} width={1000}>
                <p>{selectedRow.description}</p>
                <div className='html-content' >
                    {
                        selectedRow.content &&
                        <div dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(selectedRow.content)) }} />
                    }
                </div>
                <div >
                    {/* <Slider {...settings}> */}
                    {
                        selectedRow.images && selectedRow.images.map((image, index) => {
                            return (
                                <div key={index}>
                                    <ImagePresignedUrl src={image.url} style={{ height: 300, width: 300 }} />
                                </div>
                            )
                        })
                    }
                    {/* </Slider> */}
                </div>
            </Modal >
        </div >
    )
}

export default MemorandumComponents