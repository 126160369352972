import React, { useEffect, useState, useRef } from 'react'
import { Card, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { load } from '../actions'
import Toolbar from '@toolbar'
import './index.scss'
const TimelogComponents = () => {
    const { getByIdData } = useSelector(state => state.client.employees)
    const employeeNumber = getByIdData && getByIdData.employeeNumber
    const [pagination, setPagination] = useState({
        dateFrom: '',
        dateTo: '',
    })

    const prevPagination = useRef(pagination)
    const dispatch = useDispatch();
    const { getData, getLoading } = useSelector(state => state.client.timelogs)

    console.log("getData", getData)
    useEffect(() => {
        if (prevPagination.current !== pagination) dispatch(load({ ...pagination, employeeNumber: employeeNumber }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    return (
        <div className='page-content'>
            <Card bordered={false}>
                <Toolbar
                    title='Timelogs'
                    handleDateRange={(dateRange) => setPagination({ ...pagination, dateFrom: dateRange && dateRange[0], dateTo: dateRange && dateRange[1] })}
                />
                <Table columns={[
                    {
                        title: 'From',
                        dataIndex: 'start',
                        key: 'start'
                    },
                    {
                        title: 'To',
                        dataIndex: 'end',
                        key: 'end'
                    },
                    {
                        title: 'Work Days (in hrs)',
                        dataIndex: 'diff',
                        key: 'diff',
                        render: (item, data) => {
                            return (<a href={() => false}>{item.hour}:{item.minute} Hrs</a>)
                        }
                    },
                ]} dataSource={getData ? getData.workdays : []} rowKey="_id" style={{ overflowX: 'scroll' }} loading={getLoading} />

            </Card>
        </div >
    )
}

export default TimelogComponents