import React from 'react'
import { Row, Col, Card } from 'antd';
import './index.scss'
import { useSelector } from 'react-redux'

const Dashboard = () => {
    const { getByIdData } = useSelector(state => state.client.employees)
    console.log("getByIdData", getByIdData)
    return (
        <Card bordered={false} className='dashboard-container'>
            <Row gutter={16}>
                <Col md={6} lg={6} sm={24} xs={24}>
                    <Card className='panel-card'>
                        <div className='head-count'>
                            <h1>Vacation Leaves</h1>
                            <span>{(getByIdData.vacationLeaveDays || 0).toFixed(2)}hrs</span>
                        </div>
                    </Card>
                </Col>
                <Col md={6} lg={6} sm={24} xs={24}>
                    <Card className='panel-card'>
                        <div className='head-count'>
                            <h1>Sick Leaves</h1>
                            <span>{(getByIdData.sickLeaveDays || 0).toFixed(2)}hrs</span>
                        </div>
                    </Card>
                </Col>
                <Col md={6} lg={6} sm={24} xs={24}>
                    <Card className='panel-card'>
                        <div className='head-count'>
                            <h1>Emergency Leaves</h1>
                            <span>{(getByIdData.emergencyLeaveDays || 0).toFixed(2)}hrs</span>
                        </div>
                    </Card>
                </Col>
                <Col md={6} lg={6} sm={24} xs={24}>
                    <Card className='panel-card'>
                        <div className='head-count'>
                            <h1>Others Leaves</h1>
                            <span>{(getByIdData.othersLeaveDays || 0).toFixed(2)}hrs</span>
                        </div>
                    </Card>
                </Col>
            </Row>

        </Card>
    )
}

export default Dashboard
