import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Form, Select, DatePicker, notification, Card } from 'antd'
import { loadById, update, create } from '../actions'
import { load as loadEmployees } from '../../employee/actions';
import { educationType } from '@enums'
import moment from 'moment'
import access from '@access'
import { PAGE_VIEW, PAGE_CREATE } from '@page-actions'
import { useParams, useRouteMatch } from 'react-router-dom';
import Toolbar from '@toolbar'
import './index.scss'

const { Option } = Select
const { RangePicker } = DatePicker;
const FormEducation = (props) => {
    const [form] = Form.useForm()
    const [pageAction, setPageAction] = useState(PAGE_CREATE)
    const [search, setSearch] = useState('')
    const { id } = useParams();
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const employees = useSelector(state => state.admin.employees)
    const { getByIdData, getByIdLoading, createLoading, updateLoading } = useSelector(state => state.admin.educations)
    const userId = getByIdData.user && getByIdData.user._id

    useEffect(() => {
        if (path === '/educations/create') setPageAction(PAGE_CREATE)
        else if (path === '/educations/view/:id') {
            setPageAction(PAGE_VIEW)
            dispatch(loadById(id)).then(res => {
                form.resetFields()
                form.setFieldsValue({
                    ...res.payload,
                    yearFromTo: res.payload && [moment(res.payload.yearFrom, 'YYYY'), moment(res.payload.yearTo, 'YYYY')],
                    expiration: res.payload && moment(res.payload.expiration),
                })
            })
            if (userId) dispatch(loadEmployees({ page: 1, limit: 50, id: userId, }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            dispatch(loadEmployees({ page: 1, limit: 50, search: search, }))
        }, 1000);
        return () => clearTimeout(timerId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    function handleSubmit(value) {

        const submitData = {
            ...value,
            yearFrom: moment(value.yearFromTo[0]).format('YYYY'),
            yearTo: moment(value.yearFromTo[1]).format('YYYY')
        }

        if (pageAction === PAGE_CREATE) {
            dispatch(create({ ...submitData })).then(res => {
                if (res.type === 'educations/createFulfilled') {
                    notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                    goBack()
                }
            })
        } else if (pageAction === PAGE_VIEW) {
            dispatch(update({ id, ...submitData })).then(res => {
                if (res.type === 'educations/updateFulfilled') {
                    notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                    goBack()
                }
            })
        } else {
            notification.warning({ message: 'Opppps!', description: `Can't Submit Data.` });
        }
    }

    function goBack() {
        form.resetFields()
        props.history.goBack()
    }

    const isAccessDisabled = (!access.check('educations', 'edit') && (pageAction === PAGE_VIEW))
    return (
        <Card bordered={false}>
            <Toolbar
                title='Educations'
                handleBack={() => props.history.goBack()}
            />
            <Form
                name={`education`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (pageAction === PAGE_CREATE) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select disabled={isAccessDisabled} placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={employees.getLoading}>
                                    {
                                        employees.getData.data.map((data, index) => {
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="School"
                            name="school"
                            rules={[{ required: true, message: 'Please input school!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="School Address"
                            name="schoolAddress"
                            rules={[{ required: true, message: 'Please input school address!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Course"
                            name="course"
                            rules={[{ required: true, message: 'Please input school!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        {/* <Form.Item
                            label="License Number"
                            name="licenseNumber"
                            rules={[{ required: true, message: 'Please input license number!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item> */}

                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: true, message: 'Please input type!' }]}
                        >
                            <Select disabled={isAccessDisabled}  >
                                {
                                    Object.keys(educationType).map(function (key, index) {
                                        return (
                                            <Option value={educationType[key]} key={index}>{key}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Year From - To"
                            name="yearFromTo"
                            rules={[{ required: true, message: 'Please input year from to!' }]}
                        >
                            <RangePicker picker="year" disabled={isAccessDisabled} />
                        </Form.Item>

                        {/* <Form.Item
                            label="Expiration"
                            name="expiration"
                            rules={[{ required: true, message: 'Please input expiration!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item> */}

                        {
                            (pageAction === PAGE_VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('educations', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('educations', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('educations', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                {
                    ((access.check('educations', 'add') && (pageAction === PAGE_CREATE)) || (access.check('educations', 'edit') && (pageAction === PAGE_VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={getByIdLoading || createLoading || updateLoading} disabled={getByIdLoading || createLoading || updateLoading}  >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Card>
    )
}

export default FormEducation