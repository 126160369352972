import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Tag, Space, Button, Popconfirm, Card, Alert, notification } from 'antd'
import { load, remove } from '../actions'
import './index.scss'
import moment from 'moment'
import Toolbar from '@toolbar'
import ViewRoles from './viewRoles'
import access from '@access'
import Papa from 'papaparse'
import Permissions from '@permissions'
import { useRouteMatch } from 'react-router-dom';
import RolesModal from './modals/roles'
const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [search, setSearch] = useState('')
    const [selectedRow, setSelectedRow] = useState({
        roles: []
    })
    const [isRolesModalOpen, setIsRolesModalOpen] = useState(false)
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const prevPagination = useRef(pagination)
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const { getData, getLoading } = useSelector(state => state.admin.positions)

    useEffect(() => {
        if (prevPagination.current !== pagination) dispatch(load(pagination))
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        setTableData([...getData.data])
        return () => { }
    }, [getData])

    function handleDelete(id) {
        dispatch(remove(id)).then(res => {
            if (res.type === 'positions/removeFulfilled') {
                notification.success({ message: 'Deleted', description: 'You have successfully deleted a data.' });
                dispatch(load(pagination))
            }
        })
    }

    useEffect(() => {
        if (search) {
            const delayDebounceFn = setTimeout(() => {
                setPagination(prevState => ({ ...prevState, search }));
            }, 1300)
            return () => clearTimeout(delayDebounceFn)
        } else dispatch(load({ ...pagination, search: '' }))
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    function handleView(data) {
        props.history.push(`/positions/view/${data._id}`)
    }

    function handleDownload() {
        console.log("DOWNLOAD CSV", tableData)
        const exportData = tableData.map(data => {
            return {
                "Created Date": moment(data.createdAt).format('LLLL'),
                "Title": data.title,
                "Description": data.description,
                "Job Level": data.jobLevel,
            }
        })
        var csv = Papa.unparse(exportData);
        var unix = Date.now()
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `positions_${unix}.csv`);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `positions_${unix}.csv`);
        tempLink.click();
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 200,

        },
        props.rolesManagement ? {
            title: 'Roles',
            dataIndex: 'roles',
            key: 'roles',
            responsive: ['lg', 'md', 'sm'],
            render: (item, data) => {
                return (
                    <ViewRoles item={item} />
                )
            }
        } : {},
        !props.rolesManagement ? {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            responsive: ['lg', 'md', 'sm'],
        } : {},
        {
            title: 'Job Level',
            dataIndex: 'jobLevel',
            key: 'jobLevel',
            responsive: ['lg', 'md', 'sm'],
            // width: 200,
            // render: (item, data) => {
            //     return (
            //         <Rate value={item} style={{ color: '#108ee9' }} disabled />
            //     )
            // }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (item, data) => {
                return (
                    <div className='col-image'>
                        <Tag color="#108ee9">Active</Tag>
                    </div>
                )
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item, data) => {
                return (moment(data.createdAt).format('LL'))
            }
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <>
                        {
                            props.rolesManagement ?
                                access.check('utilities', 'manageAccess') ?
                                    <a href={() => false} onClick={() => {
                                        console.log("XXX",)
                                        const selected = data.roles ? { ...data } : { ...data, roles: [] }
                                        setSelectedRow(selected)
                                        setIsRolesModalOpen(true)
                                    }} style={{ whiteSpace: 'nowrap' }}><u>Manage Roles</u></a> : null :
                                <Space>
                                    <Button type='primary' size='small' onClick={() => handleView(data)}  >
                                        View
                                    </Button>
                                    {(access.check('positions', 'delete')) &&
                                        <Button type='danger' size='small'>
                                            <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                                Delete
                                            </Popconfirm>
                                        </Button>
                                    }
                                </Space>
                        }
                    </>
                )
            }
        },
    ];
    return (
        <Layout className='page'>
            <Alert
                style={{ margin: 24 }}
                message="Note:"
                description={
                    <Fragment>
                        <span>In this page you can <b>ADD</b>, <b>UPDATE</b>, <b>DELETE</b> data for positions.</span><br />
                        <span>By default, Creating new position data does not have roles yet. If you want to add roles in the specific position,  <a href={() => false}><u> Edit the Position Roles Management</u></a>.</span>
                    </Fragment>
                }
                type="info"
            />
            <Permissions module='positions' role='view' >
                <Card bordered={false}>
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title={`Positions with Roles Management`}
                                handleAdd={() => props.history.push(`${path}/create`)}
                                removeAdd={!access.check('positions', 'add')}
                                handleDownload={() => handleDownload()}
                                handleSearch={(search) => setSearch(search)}
                                handleSort={(sort) => setPagination({ ...pagination, sort })}
                                // handleStatus={(status) => setPagination({ ...pagination, status })}
                                statusArray={[
                                    { id: 0, label: 'Active' },
                                    { id: 1, label: 'Inactive' },
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Permissions>
            <RolesModal
                onSuccess={() => dispatch(load(pagination))}
                visible={isRolesModalOpen}
                selectedRow={selectedRow}
                onCancel={() => setIsRolesModalOpen(false)}
            />
        </Layout>
    )
}

export default List