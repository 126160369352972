
import { createSlice } from "@reduxjs/toolkit";

const forgotPassword = createSlice({
  name: 'forgotPassword',
  initialState: {
    forgotPasswordLoading: false,
    forgotPasswordFulfilled: false,
    forgotPasswordRejected: false,
    forgotPasswordData: {}

  },
  reducers: {
    postForgotPassword(state, action) {
      return {
        ...state,
        forgotPasswordLoading: true,
        forgotPasswordFulfilled: false,
        forgotPasswordRejected: false
      }
    },
    postForgotPasswordFulfilled(state, action) {
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordFulfilled: true,
        forgotPasswordData: action.payload
      }
    },
    postForgotPasswordRejected(state, action) {
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordRejected: true
      }
    },
  }
});

export const actions = forgotPassword.actions;

export default forgotPassword.reducer;