import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid';
import './index.scss'

const UploadComponent = (props) => {
    const [base64Image, setBase64Image] = useState('')
    const [imageArray, setImageArray] = useState([])

    function getBase64(file) {
        file.map(data => {
            const id = uuidv4()
            const name = `${props.fileName}_${id}.${data.type.split('/')[1]}`
            const uploadDir = `${props.uploadDir}/${name}`
            if (props.autoRename) {
                Object.defineProperty(data, 'name', {
                    writable: true,
                    value: name
                });
            }
            data.id = id
            data.url = uploadDir
            data.uploadDir = props.uploadDir
            return data
        })
        setImageArray(file)
        props.getFile && props.getFile(file)
    }

    function handleDeleteImage() {
        setBase64Image('')
    }

    function renderIcon(data) {
        switch (data.type) {
            case 'application/pdf':
                return require('../../assets/icons/pdfIcon.jpg')
            case 'text/csv':
                return require('../../assets/icons/csvIcon.png')
            default:
                return URL.createObjectURL(data)
        }
    }

    return (
        <div>
            <Dropzone disabled={props.disabled} onDrop={acceptedFiles => getBase64(acceptedFiles)} maxFiles={props.maxFiles}>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {base64Image === '' ?
                                (imageArray.length ?
                                    <div className='dropzone-container-multiple'>
                                        {
                                            imageArray.map((data, index) => {
                                                return (
                                                    <div className='dropzone-list' key={index}>
                                                        <img src={renderIcon(data)} alt={data.name} />
                                                        <span>{data.name}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <div className='dropzone-empty'>Select your {props.maxFiles || ''} {props.type} file/s or drag here. {props.disabled ? '(DISABLED)' : ''}</div>) :
                                <img src={base64Image} alt={base64Image} style={{ height: '100%', width: '100%' }} />
                            }
                        </div>
                    </section>
                )}
            </Dropzone>
            {base64Image !== '' && <a href={() => false} onClick={() => handleDeleteImage()}>Delete</a>}
        </div>
    )
}

UploadComponent.defaultProps = {
    maxFiles: 3,
    fileName: 'sample',
    type: 'image',
    autoRename: true
}

export default UploadComponent;
