import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Switch, Tabs, Divider, notification } from 'antd'
import { useDispatch } from 'react-redux'
import { update } from '../../actions'
import _ from 'lodash';
import '../index.scss'
import { defaultRoles } from '../.././../../../utilities/roles'

const Roles = (props) => {
  const [roles, setRoles] = useState([])
  const dispatch = useDispatch();

  function mergeDefaultAndExistingRoles() {
    let newDefaultRoles = []
    defaultRoles.map((defRoles, index) => {
      const found = props.selectedRow && props.selectedRow.roles.find(role => (role.title) === defRoles.title)
      if (found) {
        return newDefaultRoles.push(found)
      }
      else {
        return newDefaultRoles.push(defRoles)
      }
    })
    return newDefaultRoles
  }

  useEffect(() => {
    const roles = props.selectedRow && props.selectedRow.roles.length === 0 ? defaultRoles : mergeDefaultAndExistingRoles()
    setRoles(roles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible])

  function handleChangeRoles(value, title, roleKey) {
    setRoles(state => {
      return state.map(item => {
        if (item.title === title) {
          let roles = { ...item.roles, [roleKey]: value }
          return { ...item, roles }
        } else {
          return item
        }
      })
    });
  }

  function handlePageEnable(value, title) {
    setRoles(state => {
      return state.map(item => {
        return item.title === title ? { ...item, visible: value } : item
      })
    });
  }

  function handleSubmitRoles() {
    const submitData = {
      id: props.selectedRow._id,
      roles: roles
    }
    dispatch(update(submitData)).then(res => {
      if (res.type === 'positions/updateFulfilled') {
        notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
        props.onCancel()
        props.onSuccess && props.onSuccess()
        setRoles([])
      }
    })
  }
  function handleCancel() {
    props.onCancel()
    setRoles([])
  }

  function generateTabs() {
    return roles.map((data, i) => {
      return {
        label: `(${_.startCase(data.title)})`,
        key: i,
        children: <div>
          <h3>Page Settings <span>{`(${_.startCase(data.title)})`}</span></h3>
          <h6>Switching this to 'ON' that means you allow this ADMINISTRATOR to view the {_.startCase(data.title)} Page.</h6>
          <Switch defaultChecked={data.visible} onChange={(value) => handlePageEnable(value, data.title)} />
          <Divider style={{ backgroundColor: '#86bdf7' }} />
          <h3>Access Roles</h3>
          <Row align="bottom">
            {
              Object.keys(data.roles).map(function (key, index) {
                return (
                  <Col span={4} style={{ marginBottom: 100 }} key={index}>
                    <h6>{_.startCase(key)}</h6>
                    <Switch defaultChecked={data.roles[key]} onChange={(value) => handleChangeRoles(value, data.title, key)} />
                  </Col>
                )
              })
            }
          </Row>
        </div>
      }
    })
  }

  console.log("----------------->", generateTabs())
  return (
    <Modal
      title="Roles Management"
      className="roles-management-modal"
      centered
      visible={props.visible}
      onCancel={() => handleCancel()}
      width={1000}
      footer={null}
      destroyOnClose
    >
      <Tabs tabPosition='left' centered={true} items={generateTabs()} />
      <Row>
        <Col md={24} lg={24} sm={24} align={'right'}>
          <Button type="primary" onClick={() => handleSubmitRoles()} >
            Update Roles
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default Roles