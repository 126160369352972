import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
const ViewRoles = (props) => {
    const [showMore, setShowMore] = useState(false)
    const [data, setData] = useState([])

    function shuffleArray(data) {
        var array = [...data]
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array
    }

    useEffect(() => {
        setData(shuffleArray(props.item || []))
    }, [props.item])

    return (
        <div className='roles-column'>
            {
                props.item && data.slice(0, showMore ? props.item.length : 2).map((data, index) => {
                    return (
                        <div style={{ marginTop: 12 }} key={index}>
                            <h2 style={{ fontSize: 12 }}>{_.startCase(data.title)}</h2>
                            <h1>Page Enabled:  {data.visible ? <CheckCircleFilled style={{ color: 'green' }} /> : <CloseCircleFilled style={{ color: 'red' }} />}</h1>
                            <div style={{ whiteSpace: 'nowrap', display: 'flex' }}>
                                {
                                    Object.keys(data.roles).map(function (key, index) {
                                        return (
                                            <h1 style={{ marginRight: 12 }} key={index}>{_.startCase(key)} {data.roles[key] ? <CheckCircleFilled style={{ color: 'green' }} /> : <CloseCircleFilled style={{ color: 'red' }} />}</h1>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
            {
                data.length === 0 && <span>Dont have a roles yet.</span>
            }
            {
                (props.item && props.item.length) > 2 ?
                    <p className='action' onClick={() => setShowMore(!showMore)} >
                        {
                            !showMore ? 'Show More Roles' : 'Show Less Roles'
                        }
                    </p> : null
            }
        </div>
    )
}

export default ViewRoles
