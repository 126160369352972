import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Modal } from 'antd'
import { create, update } from '../actions'
import { ADD, VIEW, NONE } from '@page-actions'
import session from '@session'
import moment from 'moment'
import './index.scss'

const FormFileReport = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const { updateLoading } = useSelector(state => state.client.educations)

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            year: (props.selectedRow && moment(props.selectedRow.year)) || new Date(),
        })
        // eslint-disable-next-line
    }, [props.selectedRow])


    async function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            const userId = session.user().id
            dispatch(create({ userId, ...submitData })).then(res => {
                props.setActionType(NONE)
                setLoading(false)
                form.resetFields()
            })
        }

        if (props.actionType === VIEW) {
            const fileReportId = props.selectedRow._id
            dispatch(update({ id: fileReportId, ...submitData }
            )).then(res => {
                setLoading(false)
                form.resetFields()
            })
        }
    }

    return (
        <Modal forceRender title={(props.selectedRow && props.selectedRow.company) || 'Add FileReport'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`fileReport`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{ required: true, message: 'Please input title!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[{ required: true, message: 'Please input description!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={updateLoading || loading}   >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

export default FormFileReport