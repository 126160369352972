import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col, Input, Layout, Form, Divider, notification } from 'antd'
import { loadById, update } from '../actions'
import { CustomDropzone } from '../../../../utilities/customDropzone'
import { upload } from '../../../../utilities/filesController'
import session from '@session'

import moment from 'moment'
import './index.scss'

const FormEmployee = (props) => {
    const [form] = Form.useForm()
    const [userDetails, setUserDetails] = useState({})
    const [fileImage, setFileImage] = useState(undefined)
    const [fileImageObj, setFileImageObj] = useState(undefined)
    const dispatch = useDispatch();

    useEffect(() => {
        async function load() {
            const userId = session.user().id
            dispatch(loadById(userId)).then(res => {
                form.setFieldsValue({ ...res.payload })
                setUserDetails(res.payload)
            })
        }
        load()
        // eslint-disable-next-line
    }, [])

    async function handleSubmit(value) {
        const userId = session.user().id
        Promise.all([
            uploadFileImage(),
        ]).then((res) => {
            const isAllResultTrue = res.every(data => data === true)
            const submitData = { id: userId, ...value }
            if (fileImageObj) submitData.image = fileImageObj
            if (isAllResultTrue) {
                delete value.religion
                dispatch(update({ ...submitData })).then(res => {
                    notification.success({
                        message: `Profile updated`,
                    });
                })
            } else {
                notification.error({
                    message: `Failed to update data`,
                    // description: err,
                });
            }
        })
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (fileImage) {
                upload(fileImage, fileImage.name, 'images').then(data => {
                    return resolve(true)
                }).catch(err => {
                    return notification.error({
                        message: `Failed to upload image`,
                        description: err,
                    });
                })
            } else {
                return resolve(true)
            }
        })
    }

    function getFileImage(e) {
        const obj = {
            name: e.name,
            url: `images/${e.name}`
        }
        setFileImage(e)
        setFileImageObj(obj)
    }


    return (
        <Layout style={{ backgroundColor: 'white' }}>
            <Form
                name={`employee-${props.selectedId}`}
                form={form}

                onFinish={(e) => handleSubmit(e)}
            // onFinishFailed={onFinishFailed}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={24} xs={24} lg={24} sm={24}>
                        <div className='user-details' >
                            <div className='user-view-left'>
                                <CustomDropzone
                                    fileName='profile'
                                    height={200}
                                    width={200}
                                    getFile={file => { getFileImage(file) }}
                                    value={userDetails.image && userDetails.image[0] && userDetails.image[0].url}
                                    preview={false}
                                />
                                <div className='details'>
                                    <p className='name'>{`${userDetails.firstName} ${userDetails.lastName}`}</p>
                                    <p className='position'>{`${userDetails.position && userDetails.position.title}`}</p>
                                    <p className='birthdate'>Birthday: {`${moment(userDetails.birthday).format('LL')}`}</p>

                                    <div className='ratings'>
                                        <p>JOB LEVEL</p>
                                        <div className='ratings-flex'>
                                            <span className='job-level-label'>{userDetails.position && userDetails.position.jobLevel && userDetails.position.jobLevel.toFixed(1)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Divider />
                <h5 style={{ fontSize: 24 }}>Personal Information</h5>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[{ required: true, message: 'Please input last name!' }]}
                        >
                            <Input
                                disabled
                                value={userDetails.lastName} onChange={(e) => {
                                    setUserDetails({ ...userDetails, lastName: e.target.value })
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[{ required: true, message: 'Please input first name!' }]}
                        >
                            <Input disabled value={userDetails.firstName} onChange={(e) => {
                                setUserDetails({ ...userDetails, firstName: e.target.value })
                            }} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Middle Name"
                            name="middleName"
                            rules={[{ required: true, message: 'Please input middle name!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Employee Number"
                            name="employeeNumber"
                            rules={[{ required: true, message: 'Please input employee number!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[{ required: true, message: 'Please input gender!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Marital Status"
                            name="maritalStatus"
                            rules={[{ required: true, message: 'Please input maritial status!' }]}
                        >
                            <Input disabled />
                        </Form.Item>

                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Birthday"
                            name="birthday"
                            rules={[{ required: true, message: 'Please input birthday!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[{ required: true, message: 'Please input address!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Contact"
                            name="contact"
                            rules={[{ required: true, message: 'Please input contact!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="SSS Number"
                            name="sssNumber"
                            rules={[{ required: true, message: 'Please input sss number!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="TIN"
                            name="tin"
                            rules={[{ required: true, message: 'Please input TIN!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Philhealth Number"
                            name="philHealthNumber"
                            rules={[{ required: true, message: 'Please input philhealth number!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Pag-Ibig Number"
                            name="pagIbigNumber"
                            rules={[{ required: true, message: 'Please input pag ibig number!' }]}
                        >
                            <Input disabled />
                        </Form.Item>

                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Pag-Ibig Prem"
                            name="pagIbigPrem"
                            rules={[{ required: true, message: 'Please input Pag Ibig Prem!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Tax Status"
                            name="taxStatus"
                            rules={[{ required: true, message: 'Please input tax status!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input email!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            label="Account Number"
                            name="accountNumber"
                            rules={[{ required: true, message: 'Please input account number!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            label="Employment Status"
                            name="employmentStatus"
                            rules={[{ required: true, message: 'Please input employment status!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>

                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Citizenship"
                            name="citizenship"
                            rules={[{ required: true, message: 'Please input citizen ship!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            label="Religion"
                            name="religion"
                            rules={[{ required: true, message: 'Please input religion!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>

                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Date Hired"
                            name="dateHired"
                            rules={[{ required: true, message: 'Please input date hired!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            label="Date Resigned"
                            name="dateResigned"
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />
                <h5 style={{ fontSize: 24 }}>Company Code</h5>
                <Row gutter={16}>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Center Code"
                            name="centerCode"
                            rules={[{ required: true, message: 'Please input center code!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Section Code"
                            name="sectionCode"
                            rules={[{ required: true, message: 'Please input section code!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Property Code"
                            name="propertyCode"
                            rules={[{ required: true, message: 'Please input property code!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Division Code"
                            name="divisionCode"
                            rules={[{ required: true, message: 'Please input division code!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <h5 style={{ fontSize: 24 }}>Total Leave Days</h5>
                <Row gutter={16}>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Vacation Leave Days"
                            name="vacationLeaveDays"
                            rules={[{ required: true, message: 'Please input vacation leave!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Sick Leave Days"
                            name="sickLeaveDays"
                            rules={[{ required: true, message: 'Please input sick leave!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>

                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Emergency Leave Days"
                            name="emergencyLeaveDays"
                            rules={[{ required: true, message: 'Please input emergency leave!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Others Leave Days"
                            name="othersLeaveDays"
                            rules={[{ required: true, message: 'Please input others leave!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />
                <h5 style={{ fontSize: 24 }}>Salary</h5>
                <Row gutter={16}>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Rate Per Month"
                            name="ratePerMonth"
                            rules={[{ required: true, message: 'Please input rate per month!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Rate Per Hour"
                            name="ratePerHour"
                            rules={[{ required: true, message: 'Please input rate per hour!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Working Days Per Month"
                            name="workingDaysPerMonth"
                            rules={[{ required: true, message: 'Please input working days per month!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />
                <h5 style={{ fontSize: 24 }}>Allowance</h5>
                <Row gutter={16}>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Allowance amount"
                            name="allowance"
                            rules={[{ required: true, message: 'Please input allowance!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>


                {/* <Row>
                    <Col md={24} lg={24} sm={24} align={'right'}>
                        <Form.Item >
                            <Button className='primary-btn' type="primary" htmlType="submit"
                                loading={props.updateLoading || loading}
                            >
                                Submit Employee
                            </Button>

                        </Form.Item>
                    </Col>
                </Row> */}
            </Form>

        </Layout>
    )
}

export default FormEmployee