import Axios from 'axios'
import { headers } from '../../../../utilities/token'
import { actions } from '../reducers';
const API_URL = process.env.REACT_APP_API_BASE_URL


export const resetDatabase = (tables) => {
    return dispatch => {
        dispatch(actions.reset())
        return Axios.delete(`${API_URL}/reset-data?tables=${tables.toString()}`, { headers: headers })
            .then(function (response) {
                return dispatch(actions.resetFulfilled(response.data))
            })
            .catch(function (error) {
                return dispatch(actions.resetRejected(error))
            })
    }
}
