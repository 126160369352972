import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid';
import { ImagePresignedUrl } from '../renderer'
import './index.scss'

const UploadComponent = (props) => {
    const [base64Image, setBase64Image] = useState('')

    function getBase64(file) {
        file.map(data => {
            const id = uuidv4()
            const name = `${props.fileName}_${id}.${data.type.split('/')[1]}`
            data.id = id
            Object.defineProperty(data, 'name', {
                writable: true,
                value: name
            });
            data.url = name
            return data
        })

        var reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = function () {
            setBase64Image(reader.result)
        };

        props.getFile && props.getFile(file[0])
    }

    function handleDeleteImage() {
        setBase64Image('')
    }
    return (
        <div className='dropzone-container-single'  >
            <div style={{ width: props.width, height: props.height }}>
                <Dropzone onDrop={acceptedFiles => getBase64(acceptedFiles)} >
                    {({ getRootProps, getInputProps }) => (
                        <section >
                            <div className='content' {...getRootProps()}>
                                <input {...getInputProps()} />
                                {base64Image === '' ?
                                    (props.value ?
                                        <ImagePresignedUrl src={props.value} style={{ height: 200 }} preview={false} /> :
                                        <div className='dropzone-empty'>Select or Drag a file.</div>) :
                                    <img src={base64Image} alt={base64Image} />
                                }
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>

            {base64Image !== '' && <a href={() => false} onClick={() => handleDeleteImage()}>Delete</a>}
        </div>
    )
}

UploadComponent.defaultProps = {
    maxFiles: 1,
    fileName: 'sample',
    height: 200,
    width: 200
}

export default UploadComponent;
