import Axios from 'axios'
import { actions } from '../reducers';
const API_URL = process.env.REACT_APP_API_BASE_URL
export const submitResetPassword = (data) => {
  return dispatch => {
    dispatch(actions.postResetPassword())
    return Axios.post(`${API_URL}/reset-password/${data.id}`, { password: data.password }, {
      headers: {
        "Authorization": `${data.token}`,
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    }).then(function (response) {
      console.log("RESPONSE", response)
      return dispatch(actions.postResetPasswordFulfilled(response.data))
    }).catch(function (error) {
      return dispatch(actions.postResetPasswordRejected(error))
    })
  }
}

export const checkSession = (data) => {
  return dispatch => {
    dispatch(actions.postCheckSession())
    return Axios.post(`${API_URL}/check-session`, data)
      .then(function (response) {
        return dispatch(actions.postCheckSessionFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.postCheckSessionRejected(error))
      })
  }
}
