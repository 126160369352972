import Axios from 'axios'
import { headers, getToken } from './token'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const get = (filePathName) => {
    return new Promise(function (resolve, reject) {
        return Axios.get(`${API_URL}/file?fileName=${filePathName}`, { headers: headers })
            .then(function (response) {
                return resolve(response.data.url)
            }).catch(function (error) {
                return reject(error)
            })
    });
}

export const upload = (file, filePathName, dir) => {
    //remove double slash
    const filePath = `${dir}/${filePathName}`.replaceAll('//', '/')
    var bodyFormData = new FormData();
    bodyFormData.append('file', file);

    return new Promise(function (resolve, reject) {
        return Axios.post(`${API_URL}/files?fileName=${filePath}`, bodyFormData, {
            headers: {
                "Authorization": `${getToken()}`,
                "Accept": "application/json",
                "Content-Type": "multipart/form-data"
            }
        }).then(function (response) {
            return resolve(response.data)
        }).catch(function (error) { })
    });
}
