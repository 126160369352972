import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Card } from 'antd'
import { loadById, } from '../actions'
import { useParams } from 'react-router-dom';
import './index.scss'
import Toolbar from '@toolbar'
import LatesModal from './modals/lates'
import UndertimesModal from './modals/undertimes'
import WorkdaysModal from './modals/workdays'
import LeavesModal from './modals/leaves'
import OvertimesModal from './modals/overtimes'
import OverallModal from './modals/overall'
import AbsentWithoutPayModal from './modals/awop'
import Papa from 'papaparse'
import { hoursSum } from '@time-helper'
const FormPosition = (props) => {
    const { id } = useParams();
    const [search, setSearch] = useState('')
    const dispatch = useDispatch();
    const { getByIdData, getByIdLoading } = useSelector(state => state.admin.processDtr)
    const [tableData, setTableData] = useState([])

    const [showLateModal, setShowLateModal] = useState(false)
    const [latesData, setLatesData] = useState([])

    const [showUndertimeModal, setShowUndertimeModal] = useState(false)
    const [undertimesData, setUndertimesData] = useState([])

    const [showWorkdayModal, setShowWorkdayModal] = useState(false)
    const [workdaysData, setWorkdaysData] = useState([])

    const [showLeaveModal, setShowLeaveModal] = useState(false)
    const [leavesData, setLeavesData] = useState([])

    const [showOvertimeModal, setShowOvertimeModal] = useState(false)
    const [overtimesData, setOvertimesData] = useState([])

    const [showOverallModal, setShowOverallModal] = useState(false)
    const [overallData, setOverallData] = useState([])

    const [showAwopModal, setShowAwopModal] = useState(false)
    const [awopData, setAwopData] = useState([])

    useEffect(() => {
        dispatch(loadById(id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTableData(getByIdData.value)
        return () => {
            setTableData([])
        }
    }, [getByIdData])

    useEffect(() => {
        if (search) {
            const delayDebounceFn = setTimeout(() => {
                const newData = getByIdData.value.filter(data => data.employeeNumber === search)
                setTableData(newData)
            }, 1300)
            return () => clearTimeout(delayDebounceFn)
        } else setTableData(getByIdData.value)
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    function handleDownload() {
        const exportData = getByIdData?.value?.map(data => {
            const lates = data.timelogs ? data.timelogs.lates : []
            const undertimes = data.timelogs ? data.timelogs.undertimes : []
            const workdays = data.timelogs ? data.timelogs.workdays : []
            const overtimes = data.overtimes ? data.overtimes : []
            const awop = data.awop
            const leavesWPay = data.leaves ? data.leaves.filter(leave => leave.isWithPay === 1) : []
            const leaveWOPay = data.leaves ? data.leaves.filter(leave => leave.isWithPay === 0) : []
            const leavesWPayHours = leavesWPay.reduce((accumulator, value) => {
                return accumulator + value.hours;
            }, 0);
            const leavesWOPayHours = leaveWOPay.reduce((accumulator, value) => {
                return accumulator + value.hours;
            }, 0);
            const overtimeHours = overtimes.reduce((accumulator, value) => {
                return accumulator + value.hours;
            }, 0);
            return {
                "Employee Name": `${data ? data.firstName : ''} ${data ? data.lastName : ''}`,
                "Employee Number": data.employeeNumber,
                "Total Number of Hours": `${hoursSum(workdays, true) || 0} ${hoursSum(workdays, true) <= 0 ? 'hr' : 'hrs'}`,
                "Lates(Hrs)": `${hoursSum(lates) || 0} ${hoursSum(lates) <= 0 ? 'hr' : 'hrs'}`,
                "Undertimes(Hrs)": `${hoursSum(undertimes) || 0} ${hoursSum(undertimes) <= 0 ? 'hr' : 'hrs'}`,
                "Overtimes(Hrs)": `${overtimeHours} Hrs`,
                "Leaves W Pay": `${leavesWPayHours} Hrs`,
                "Leaves WO Pay": `${leavesWOPayHours} Hrs`,
                "Absent": `${awop?.length || 0}`
            }
        })
        console.log("exportData", exportData)
        var csv = Papa.unparse(exportData);
        var unix = Date.now()
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `DTR_${unix}.csv`);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `DTR_${unix}.csv`);
        tempLink.click();
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Employee Name',
            dataIndex: 'empName',
            key: 'empName',
            render: (item, data) => `${data ? data.firstName : ''} ${data ? data.lastName : ''}`
        },
        {
            title: 'Employee Number',
            dataIndex: 'employeeNumber',
            key: 'employeeNumber',
        },
        {
            title: 'Work Days (in hrs)',
            dataIndex: 'workHours',
            key: 'workHours',
            render: (item, data) => {
                const workdays = data?.timelogs?.workdays
                return <a href={() => false} onClick={() => {
                    if (workdays?.length) {
                        setShowWorkdayModal(true)
                        setWorkdaysData(workdays)
                    }
                }} disabled={!(workdays?.length)}>{`${hoursSum(workdays, true) || 0} ${hoursSum(workdays, true) <= 0 ? 'hr' : 'hrs'}`}</a>
            }
        },
        {
            title: 'Lates',
            dataIndex: 'lates',
            key: 'lates',
            render: (item, data) => {
                const lates = data?.timelogs?.lates
                return <a href={() => false} onClick={() => {
                    if (lates && lates.length) {
                        setShowLateModal(true)
                        setLatesData(lates)
                    }
                }} disabled={!(lates?.length)}>{`${hoursSum(lates) || 0} ${hoursSum(lates) <= 0 ? 'hr' : 'hrs'}`}</a>
            }
        },
        {
            title: 'Undertimes',
            dataIndex: 'undertimes',
            key: 'undertimes',
            render: (item, data) => {
                const undertimes = data?.timelogs?.undertimes
                return <a href={() => false} onClick={() => {
                    if (undertimes?.length) {
                        setShowUndertimeModal(true)
                        setUndertimesData(undertimes)
                    }
                }} disabled={!(undertimes?.length)}>{`${hoursSum(undertimes) || 0} ${hoursSum(undertimes) <= 0 ? 'hr' : 'hrs'}`}</a>
            }
        },
        {
            title: 'Leaves W/ Pay ',
            dataIndex: 'leavesWPay',
            key: 'leavesWPay',
            render: (item, data) => {
                const leaves = data?.leaves?.filter(leave => leave.isWithPay === 1)
                const countHours = leaves?.reduce((accumulator, value) => {
                    return accumulator + value.hours;
                }, 0);
                return <a href={() => false} onClick={() => {
                    if (leaves?.length) {
                        setShowLeaveModal(true)
                        setLeavesData(leaves)
                    }
                }} disabled={!(leaves?.length)}> {countHours || 0}:00 Hrs</a>
            }
        },
        {
            title: 'Leaves W/O Pay ',
            dataIndex: 'leavesWPay',
            key: 'leavesWPay',
            render: (item, data) => {
                const leaves = data?.leaves?.filter(leave => leave.isWithPay === 0)
                const countHours = leaves?.reduce((accumulator, value) => {
                    return accumulator + value.hours;
                }, 0);
                return <a href={() => false} onClick={() => {
                    if (leaves?.length) {
                        setShowLeaveModal(true)
                        setLeavesData(leaves)
                    }
                }} disabled={!(leaves?.length)}> {countHours || 0}:00 Hrs</a>
            }
        },
        {
            title: 'Overtimes',
            dataIndex: 'overtimes',
            key: 'overtimes',
            render: (item, data) => {
                const overtimes = data?.overtimes
                const countHours = item?.reduce((accumulator, value) => {
                    return accumulator + value.hours;
                }, 0);
                console.log("ssssss", overtimes, countHours)
                return <a href={() => false} onClick={() => {
                    if (overtimes?.length) {
                        setShowOvertimeModal(true)
                        setOvertimesData(overtimes)
                    }
                }} disabled={!(overtimes?.length)}> {countHours || 0}:00 Hrs</a>
            }
        },
        {
            title: 'Absents',
            dataIndex: 'awop',
            key: 'awop',
            render: (item, data) => {
                const awop = data.awop
                return <a href={() => false} onClick={() => {
                    if (awop && awop.length) {
                        setShowAwopModal(true)
                        setAwopData(awop)
                    }
                }} disabled={!(awop?.length)}>{awop?.length || 0}</a>
            }
        },
        {
            title: ' ',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <Button type='primary' size='small' onClick={() => {
                        setShowOverallModal(true)
                        setOverallData(data)
                    }}  >
                        View
                    </Button>
                )
            }
        }

    ];

    return (
        <Card bordered={false}>
            <Toolbar
                title="DTR - Time Monitoring"
                handleBack={() => props.history.goBack()}
                handleDownload={() => handleDownload()}
                handleSearch={(search) => setSearch(search)}
            />
            <Table columns={columns} dataSource={dataSource} style={{ overflowX: 'scroll' }} rowKey={obj => obj && obj._id} loading={getByIdLoading} />
            <WorkdaysModal visible={showWorkdayModal} onCancel={() => setShowWorkdayModal(false)} data={workdaysData} />
            <UndertimesModal visible={showUndertimeModal} onCancel={() => setShowUndertimeModal(false)} data={undertimesData} />
            <LatesModal visible={showLateModal} onCancel={() => setShowLateModal(false)} data={latesData} />
            <LeavesModal visible={showLeaveModal} onCancel={() => setShowLeaveModal(false)} data={leavesData} />
            <OvertimesModal visible={showOvertimeModal} onCancel={() => setShowOvertimeModal(false)} data={overtimesData} />
            <AbsentWithoutPayModal visible={showAwopModal} onCancel={() => setShowAwopModal(false)} data={awopData} />
            <OverallModal visible={showOverallModal} onCancel={() => setShowOverallModal(false)} data={overallData} />
        </Card>
    )
}

export default FormPosition