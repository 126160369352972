import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Row, Col, Tag, Table, Space, Button, Card, Popconfirm, notification, Modal, DatePicker, Spin, Alert } from 'antd'
import { create, load, remove } from '../actions'
import moment from 'moment'
import './index.scss'
import Toolbar from '@toolbar'
import Permissions from '@permissions'
import TimeAgo from 'javascript-time-ago'
const timeAgo = new TimeAgo('en-US')
const { RangePicker } = DatePicker;

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [dateRange, setDateRange] = useState('')
    const [showAddModal, setShowAddModal] = useState(false)
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
        startDate: null,
        endDate: null
    })

    const { getData, getLoading, createLoading } = useSelector((state) => state.admin.processDtr)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(load(pagination))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    useEffect(() => {
        setTableData([...getData.data])
        return () => {
            setTableData([])
        }
    }, [getData])




    function handleView(data) {
        props.history.push(`/process-dtr/view/${data._id}`)
    }

    function handleDelete(id) {
        dispatch(remove(id)).then(res => {
            if (res.type === 'processDtr/removeFulfilled') {
                notification.success({ message: 'Deleted', description: 'You have successfully deleted a data.' });
                dispatch(load(pagination))
            }
        })
    }

    function handleSubmit() {
        const submitData = { dateFrom: dateRange[0], dateTo: dateRange[1] }
        dispatch(create(submitData)).then(res => {
            if (res.type === 'processDtr/createFulfilled') {
                dispatch(load(pagination))
                notification.success({ message: 'Created', description: 'You have successfully generated a cut-off data.' });
            }
        }).finally(() => {
            setShowAddModal(false)
        })
    }

    function handleAdd() {
        setShowAddModal(true)
    }

    function onChangeRangeDate(value) {
        setDateRange(value)
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Cut-Off Date (From → To)',
            dataIndex: 'dateFrom',
            key: 'dateFrom',
            render: (item, data) => <span>{moment(data.dateFrom).format('LL')} <b style={{ fontSize: 16 }}>→</b> {moment(data.dateTo).format('LL')}</span>
        },
        {
            title: 'Is Validated?',
            dataIndex: 'isValidated',
            key: 'isValidated',
            responsive: ['lg', 'md'],
            render: (item) => {
                return item === 0 ? <Tag color="magenta">Invalidated</Tag> : <Tag color="green">Validated</Tag>
            }
        },
        {
            title: 'Created By',
            dataIndex: 'postBy',
            key: 'postBy',
            render: (item) => {
                return `${item ? item.firstName : ''} ${item ? item.lastName : ''}`
            }
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item) => {
                return timeAgo.format(new Date(item))
            }
        },
        {
            title: ' ',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <Space>
                        <Button type='primary' size='small' onClick={() => handleView(data)}  >
                            View
                        </Button>
                        {/* {(access.check('processDtr', 'download')) && data.isValidated === 1 */}
                        {/* {(data.isValidated === 1) && */}
                        {/* } */}
                        {/* {(access.check('processDtr', 'delete') && data.isValidated === 0) && */}
                        {/* {(data.isValidated === 0) && */}
                        <Button type='danger' size='small'>
                            <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                Delete
                            </Popconfirm>
                        </Button>
                        {/* } */}
                    </Space>
                )
            }
        },
    ];
    return (
        <Layout className='page' >
            <Permissions module='processDtr' role='view' >
                <Card bordered={false}>
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title="DTR - Process"
                                handleAdd={() => handleAdd()}
                                handleDateRange={(dateRange) => setPagination({ ...pagination, startDate: dateRange && dateRange[0], endDate: dateRange && dateRange[1] })}
                            />
                            <Table columns={columns} dataSource={dataSource} style={{ overflowX: 'scroll' }} rowKey={obj => obj._id} loading={getLoading} />
                        </Col>
                    </Row>
                </Card>
            </Permissions>
            <Modal
                title="Process Cut-Off Records"
                onOk={handleSubmit}
                onCancel={() => setShowAddModal(false)}
                visible={showAddModal}
                confirmLoading={createLoading}
            >
                <Spin spinning={createLoading}>
                    <Alert
                        message="Processign Cut- Off Records"
                        description="Processing Cut-off Records collects data from different tables including (Users, Leaves and Integrated System for Biometric) which will generate and compute all the time records of their own data. Pressing the 'OK' button will process and takes some time or maybe long that depends to the 'processing data'. Please stand by."
                        type="info"
                        showIcon
                    /><br />
                    <RangePicker showTime style={{ padding: '12px 11px 4px 20px', borderRadius: 8, width: '100%' }}
                        value={dateRange}
                        onChange={(e) => onChangeRangeDate(e)}
                    />
                </Spin>
            </Modal>
        </Layout>
    )
}

export default List