import React from 'react'
import Toolbar from '@toolbar'
import { Card, Layout } from 'antd'
import { EmbedPresignedUrl } from '@renderer'
import { useSelector } from 'react-redux'
const PayslipComponent = () => {
    const { getByIdData } = useSelector(state => state.client.employees)
    const employeeNumber = getByIdData && getByIdData.employeeNumber
    return (
        <Layout className='overtimes-page'>
            <Card bordered={false}>
                <Toolbar title='Payslip' />
                <EmbedPresignedUrl key={'payslip'} src={`payslip/${employeeNumber}.pdf`} style={{ width: '100%', height: '100vh' }} />
            </Card>
        </Layout >
    )
}

export default PayslipComponent