import React from 'react'
import { Modal, Tag } from 'antd'
import { hoursValidate } from '@time-helper'
const WorkdaysModal = (props) => {
    return (
        <Modal
            title="Work days (in hrs)"
            open={props.visible}
            footer={null}
            onCancel={() => props.onCancel()}
            width={710}
        >
            <table style={{ width: '100%', overflowX: 'scroll' }}>
                <tbody>
                    {
                        props.data && props.data.map((data, i) => {
                            return (
                                <tr key={i}>
                                    <td><Tag color="green">IN</Tag>{data.start}</td>
                                    <td><Tag color="gold">OUT</Tag>{data.end}</td>
                                    <td style={{ color: 'red', fontWeight: 'bold' }}>{hoursValidate(`${data.diff.hour}:${data.diff.minute}`)} hrs</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Modal>
    )
}

export default WorkdaysModal