import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import login from '../login/reducers'
import allowances from '../allowances/reducers'
import educations from '../educations/reducers'
import dependents from '../dependents/reducers'
import documents from '../documents/reducers'
import references from '../references/reducers'
import overtimes from '../overtimes/reducers'
import fileReports from '../fileReports/reducers'
import businessTrips from '../businessTrips/reducers'
import personalMovements from '../personalMovements/reducers'
import announcements from '../announcements/reducers'
import timeRequests from '../timeRequests/reducers'
import performances from '../performances/reducers'
import memorandums from '../memorandums/reducers'
import attendances from '../attendances/reducers'
import accountabilities from '../accountabilities/reducers'
import employmentHistories from '../employmentHistories/reducers'
import medicalHistories from '../medicalHistories/reducers'
import disciplinaryActions from '../disciplinaryActions/reducers'
import employees from '../profile/reducers'
import leaves from '../leaves/reducers'
import timelogs from '../timelogs/reducers'

const rootReducer = combineReducers({
  form,
  login,
  allowances,
  educations,
  dependents,
  documents,
  references,
  overtimes,
  fileReports,
  businessTrips,
  personalMovements,
  announcements,
  timeRequests,
  performances,
  memorandums,
  attendances,
  accountabilities,
  employmentHistories,
  medicalHistories,
  disciplinaryActions,
  employees,
  leaves,
  timelogs
})

export default rootReducer
