import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Form, Select, notification, Card } from 'antd'
import { loadById, update, create } from '../actions'
import { PAGE_VIEW, PAGE_CREATE } from '@page-actions'
import access from '@access'
import { useParams, useRouteMatch } from 'react-router-dom';
import './index.scss'
import Toolbar from '@toolbar'

const { Option } = Select
const FormPosition = (props) => {
    const [form] = Form.useForm()
    const [pageAction, setPageAction] = useState(PAGE_CREATE)
    const { id } = useParams();
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const { getByIdLoading, createLoading, updateLoading } = useSelector(state => state.admin.positions)

    useEffect(() => {
        if (path === '/positions/create') setPageAction(PAGE_CREATE)
        else if (path === '/positions/view/:id') {
            setPageAction(PAGE_VIEW)
            dispatch(loadById(id)).then(res => {
                form.resetFields()
                form.setFieldsValue({ ...res.payload, })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleSubmit(value) {
        const submitData = { ...value, }
        if (pageAction === PAGE_CREATE) {
            dispatch(create({ ...submitData })).then(res => {
                if (res.type === 'positions/createFulfilled') {
                    notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                    goBack()
                }
            })
        } else if (pageAction === PAGE_VIEW) {
            dispatch(update({ id, ...submitData })).then(res => {
                if (res.type === 'positions/updateFulfilled') {
                    notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                    goBack()
                }
            })
        } else {
            notification.warning({ message: 'Opppps!', description: `Can't Submit Data.` });
        }
    }

    function goBack() {
        form.resetFields()
        props.history.goBack()
    }

    const isAccessDisabled = (!access.check('positions', 'edit') && (pageAction === PAGE_VIEW))
    return (
        <Card bordered={false}>
            <Toolbar
                title='Positions'
                handleBack={() => props.history.goBack()}
            />
            <Form
                name={`position-${props.selectedId}`}
                form={form}

                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input title!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please input description!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Job Level"
                            name="jobLevel"
                            rules={[{ required: true, message: 'Please input job level!' }]}
                        >
                            <Select disabled={isAccessDisabled}>
                                <Option value={4}>4</Option>
                                <Option value={5}>5</Option>
                                <Option value={6}>6</Option>
                                <Option value={7}>7</Option>
                                <Option value={8}>8</Option>
                                <Option value={9}>9</Option>
                                <Option value={10}>10</Option>
                                <Option value={11}>11</Option>
                                <Option value={12}>12</Option>
                                <Option value={13}>13</Option>
                                <Option value={14}>14</Option>
                                <Option value={15}>15</Option>
                                <Option value={16}>16</Option>
                                <Option value={17}>17</Option>
                                <Option value={18}>18</Option>
                                <Option value={19}>19</Option>
                                <Option value={20}>20</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {
                    ((access.check('positions', 'add') && (pageAction === PAGE_CREATE)) || (access.check('positions', 'edit') && (pageAction === PAGE_VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={getByIdLoading || createLoading || updateLoading} disabled={getByIdLoading || createLoading || updateLoading}  >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Card>
    )
}

export default FormPosition