import React, { useEffect, useState } from 'react'
import { Row, Col, Pagination, Modal, Table, Card, Space, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import TimeAgo from 'javascript-time-ago'
import { load } from '../actions'
import { ImagePresignedUrl } from '@renderer'
import session from '@session'
import draftToHtml from 'draftjs-to-html';
import Slider from "react-slick";
import en from 'javascript-time-ago/locale/en.json'
import Toolbar from '@toolbar'
import './index.scss'

TimeAgo.addDefaultLocale(en)

// Create formatter (English).
const timeAgo = new TimeAgo('en-US')
// Create formatter (English).
// const timeAgo = new TimeAgo('en-US')
const AnnouncementComponents = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [modalViewShow, setModalViewShow] = useState(false)
    const [selectedRow, setSelectedRow] = useState({})
    const dispatch = useDispatch();
    const { getData, getLoading } = useSelector(state => state.client.announcements)

    useEffect(() => {
        dispatch(load(pagination))
        return () => { }
        // eslint-disable-next-line
    }, [])

    function onChangePagination(page, limit) {
        const newPagination = {
            page: page,
            limit: limit,
            search: pagination.search
        }
        dispatch(load(newPagination))
        setPagination(prevState => ({
            ...prevState,
            page, limit
        }));
    }

    async function handleRowClick(e, data) {
        setModalViewShow(true)
        setSelectedRow(data)

        const userId = session.user().id
        const submitData = {
            viewerId: userId,
            docId: data._id
        }
        console.log("submitData", submitData)
        // props.onViewerAnnouncement({ ...submitData })
    }


    const settings = {
        customPaging: function (i) {
            return (
                <ImagePresignedUrl src={selectedRow.images[i] && selectedRow.images[i].url} preview={false} />
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className='page-content'>
            <Card bordered={false}>
                <Toolbar
                    title='Announcements'
                    handleSearch={(search) => setPagination({ ...pagination, search })}
                />
                <Table columns={[
                    {
                        title: 'Title',
                        dataIndex: 'title',
                        key: 'title'
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                    },
                    {
                        title: 'Created Date',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        responsive: ['lg', 'md', 'sm'],
                        render: (item, data) => {
                            return <span> {timeAgo.format(new Date(data.createdAt))} </span>
                        }
                    },
                    {
                        title: 'Images / Files',
                        dataIndex: 'images',
                        key: 'images',
                        responsive: ['lg', 'md', 'sm'],
                        render: (item, data) => {
                            return <div className='files'>
                                {
                                    data.images && data.images.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <ImagePresignedUrl src={item.url} style={{ width: 80, height: 100 }} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    },
                    {
                        title: '',
                        dataIndex: 'actions',
                        key: 'actions',
                        render: (item, data) => {
                            return (
                                <Space>
                                    <Button type='primary' size='small' onClick={(e) => {
                                        handleRowClick(e, data)
                                    }}  >
                                        View
                                    </Button>
                                </Space>
                            )
                        }
                    },
                ]} dataSource={getData.data} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />

            </Card>
            <Row style={{ marginTop: 30, width: '100%' }}>
                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                </Col>
            </Row>
            <Modal forceRender title={selectedRow.title} visible={modalViewShow} footer={null} onCancel={() => setModalViewShow(false)} width={1000}>
                <p>{selectedRow.description}</p>
                <div className='html-content' >
                    {
                        selectedRow.content &&
                        <div dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(selectedRow.content)) }} />
                    }
                </div>
                <div >
                    <Slider {...settings}>
                        {
                            selectedRow.images && selectedRow.images.map((image, index) => {
                                return (
                                    <div key={index}>
                                        <ImagePresignedUrl src={image.url} style={{ height: 300, width: 300 }} />
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </Modal>
        </div >
    )
}

export default AnnouncementComponents