import React, { useEffect, useState } from 'react'
import { Image } from 'antd'
import NoImage from '../../assets/images/noavailableimage.png'
import { get } from '../filesController'
import { LoadingOutlined } from '@ant-design/icons'

const ImagePresignedUrl = (props) => {
    const [url, setUrl] = useState('')
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        if (props.src && (typeof props.src === 'string')) {
            async function getData() {
                const fetchedUrl = await get(props.src)
                setUrl(fetchedUrl)
                setLoading(false)
            }
            getData()
        } else setLoading(false)

        return () => {
            setUrl('')
            setLoading(false)
        };
    }, [props.src])

    const height = props.style && props.style.height
    const width = props.style && props.style.width
    const objectFit = props.objectFit || 'contain'
    return (
        <div style={{ height, width }} key={Math.random()}>
            {
                loading ? <div><LoadingOutlined /></div> :
                    <Image
                        style={{ ...props.style, width: '100%', objectFit }}
                        src={url || NoImage}
                        width={width}
                        height={height}
                        id={props.id}
                        className={props.className}
                        preview={props.preview}
                    />
            }
        </div>
    )
}

export default ImagePresignedUrl