import Axios from 'axios'
import { actions } from '../reducers';
const API_URL = process.env.REACT_APP_API_BASE_URL
export const submitForgotPassword = (data) => {
  return dispatch => {
    dispatch(actions.postForgotPassword())
    return Axios.post(`${API_URL}/forgot-password`, { ...data })
      .then(function (response) {
        console.log("RESPONSE", response)
        return dispatch(actions.postForgotPasswordFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.postForgotPasswordRejected(error))
      })
  }
}
