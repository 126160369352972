import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Modal, notification } from 'antd'
import { update, create } from '../actions'
import { ADD, VIEW, NONE } from '@page-actions'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import session from '@session'
import { upload } from '../../../../utilities/filesController'
import { ImagePresignedUrl } from '@renderer'
import enumMedicalHistoryType from '../../../../utilities/enumMedicalHistoryType'
import './index.scss'
import moment from 'moment'
const { Option } = Select
const FormMedicalHistory = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([])
    const [listOfImageObject, setListOfImageObject] = useState([])
    const dispatch = useDispatch();
    const { updateLoading } = useSelector(state => state.client.medicalHistories)

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            date: (props.selectedRow && moment(props.selectedRow.date)) || new Date(),
        })
        props.selectedRow && props.selectedRow.images && setImages(props.selectedRow.images)
        // eslint-disable-next-line
    }, [props.selectedRow])

    function getFileImage(arrayImageObj) {
        setListOfImageObject(arrayImageObj)
    }

    async function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            const userId = session.user().id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    dispatch(create({ userId, images: [...images, ...listOfImageObject], ...submitData })).then(res => {
                        if (res.type === 'medicalHistories/createFulfilled') {
                            props.setActionType(NONE)
                            setLoading(false)
                            form.resetFields()
                            notification.success({
                                message: `Added`,
                                description: 'You have successfully added information.',
                            });
                        }
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }

        if (props.actionType === VIEW) {
            const medicalHistoryId = props.selectedRow._id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    dispatch(update({ id: medicalHistoryId, images: [...images, ...listOfImageObject], ...submitData })).then(res => {
                        if (res.type === 'medicalHistories/updateFulfilled') {
                            setLoading(false)
                            form.resetFields()
                            notification.success({
                                message: `Updated`,
                                description: 'You have successfully updated information.',
                            });
                        }
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (listOfImageObject.length) {
                listOfImageObject.map(item => {
                    return upload(item, item.name, item.uploadDir).then(data => {
                        return resolve(true)
                    }).catch(err => {
                        return notification.error({
                            message: `Failed to upload image`,
                            description: err,
                        });
                    })
                })
            } else {
                return resolve(true)
            }
        })
    }

    function handleRemoveSelectedImage(id) {
        const fiteredImage = images.filter(image => image.id !== id)
        setImages(fiteredImage)
    }

    return (
        <Modal forceRender title={(props.selectedRow && props.selectedRow.company) || 'Add Medical History'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`medicalHistory-${props.selectedId}`}
                    form={form}

                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[{ required: true, message: 'Please input description!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Type"
                                name="type"
                                rules={[{ required: true, message: 'Please input type!' }]}
                            >
                                <Select  >
                                    {
                                        Object.keys(enumMedicalHistoryType).map(function (key, index) {
                                            return (
                                                <Option value={enumMedicalHistoryType[key]} key={index}>{key}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="date"
                                name="date"
                                rules={[{ required: true, message: 'Please input date!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <CustomDropzoneMultiple
                                fileName='medical'
                                uploadDir='medical'
                                height={200}
                                width={200}
                                getFile={file => { getFileImage(file) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <div className='d-flex'>
                                {
                                    images.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <ImagePresignedUrl src={item.url} style={{ width: 200, height: 150 }} />
                                                <span className='red pointer' onClick={() => handleRemoveSelectedImage(item.id)}>Delete</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={updateLoading || loading}   >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

export default FormMedicalHistory