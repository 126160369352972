import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Row, Col, Button, notification, Alert } from 'antd'
import { submitForgotPassword } from '../actions'
import './index.scss'
import { Link } from 'react-router-dom'
const ForgotPassword = () => {
    const [isSuccess, setIsSuccess] = useState(false)
    const dispatch = useDispatch();
    const { forgotPasswordLoading } = useSelector(state => state.forgotPassword)

    const onFinish = (values) => {
        const submitData = {
            email: values.email,
            url: `${window.location.origin}/change-password`
        }
        dispatch(submitForgotPassword(submitData)).then(res => {
            if (res.type === 'forgotPassword/postForgotPasswordFulfilled') {
                notification.success({ message: 'Success!', description: `Please check your inbox email. Thanks` });
                setIsSuccess(true)
            }
            if (res.type === 'forgotPassword/postForgotPasswordRejected') {
                notification.warning({ message: 'Invalid!', description: `The email address you entered is invalid or does not exist in our system.` });
            }
        })
    };
    return (
        <Fragment>
            <Row justify='center'>
                <Col lg={24} md={24} sm={24} xs={24} className='container'>
                    <div className='forgot-password-container'>
                        <div className='forgot-password-form'>
                            <div className='title-header'>
                                <h1>Forgot Password</h1>
                            </div>
                            {!isSuccess ? <div>
                                <Alert
                                    message="CHECK YOUR EMAIL"
                                    description="Clicking submit, the system will send you an email with a URL in it to verify that it is you."
                                    type="info"
                                    showIcon
                                />
                                <Form
                                    name='ForgotPassword'
                                    onFinish={(e) => onFinish(e)}
                                    style={{ marginTop: 12 }}
                                >
                                    <Form.Item
                                        name='email'
                                        label='Email'
                                        rules={[{ required: true, message: 'Please input your email!' }]}
                                    >
                                        <Input className='primary-input' />
                                    </Form.Item>

                                    <Form.Item>
                                        <div style={{ display: 'flex' }}>
                                            <Button type='primary' style={{ width: '100%', marginTop: 10 }} disabled={forgotPasswordLoading} className='primary-btn' loading={forgotPasswordLoading} htmlType='submit'>
                                                Submit
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div> : <Alert
                                message="EMAIL SENT!"
                                description="The system has just sent you an email to allow you to reset your password. Please wait for the email to be delivered and follow the instructions contained inside."
                                type="success"
                                showIcon
                            />}
                            <div className='forgot-password-reminder'>
                                <p>Go back to Login? <Link to='/'>Click here</Link></p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment >
    )
}

export default ForgotPassword