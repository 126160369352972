import React, { useEffect, useState, useRef } from 'react'
import { Card, Steps, Button, Input, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { loadGeoCode, sign } from '../actions'
import Toolbar from '@toolbar'
import { geoCodeReverse } from '../../../../utilities/googleapis'
import './index.scss'
import Clock from 'react-clock'
import moment from 'moment'
import 'react-clock/dist/Clock.css';
import session from '@session'
const PunchInComponents = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm()
    const { postSignData, postSignLoading } = useSelector(state => state.client.timelogs)
    const [location, setLocation] = useState({ latitude: "", longitude: "" })
    const [address, setAddress] = useState("")
    const [step, setStep] = useState(0)
    const [getLocationLoading, setGetLocationLoading] = useState(false)
    const [time, setTime] = useState(new Date());
    const [hasError, setHasError] = useState(false)
    useEffect(() => {
        const interval = setInterval(() => setTime(new Date()), 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    function handleGetLocation() {
        setGetLocationLoading(true)
        navigator.geolocation.getCurrentPosition(function (position) {
            getGeoCodeReverse(position.coords.latitude, position.coords.longitude)
            const currLoc = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            }
            setLocation(currLoc)

        }, function (error) {
            // setDisabled(true)
            alert(error)
            setGetLocationLoading(false)
        })

        async function getGeoCodeReverse(latitude, longitude) {
            const address = await geoCodeReverse(latitude, longitude)
            setAddress(address.formatted_address)
            setGetLocationLoading(false)
            setStep(1)
        }
    }

    function handleTimeCheck() {
        setStep(2)
    }

    function handleSubmit(e) {
        setHasError(false)

        const sessionUser = session.user()
        const submitData = {
            id: sessionUser._id,
            location: address,
            latitude: location.latitude,
            longitude: location.longitude,
            password: e.password
        }

        dispatch(sign(submitData)).then(res => {
            console.log("SSSSSSSSSSSSSSSSS", res)
            if (res && res.type === "timelogs/postSignFulfilled") {
                setStep(4)
                setLocation({ latitude: "", longitude: "" })
                setAddress("")
                form.setFieldsValue({
                    password: "",
                })
            } else {
                setHasError(true)
            }
        })
    }

    const currentDateTime = moment(new Date()).format("LLLL")
    return (
        <div className='page-content'>
            <Card bordered={false}>
                <Toolbar
                    title='Punch In'
                />
                <Card>
                    <Steps
                        size="small"
                        current={step}
                        items={[
                            {
                                title: 'Get Location',
                            },
                            {
                                title: 'Time Check',
                            },
                            {
                                title: 'Input Password',
                            },
                            {
                                title: 'Done',
                            },
                        ]}
                    />
                    <Form
                        name='punch'
                        form={form}
                        onFinish={(e) => handleSubmit(e)}
                    >
                        <div className='punchin-content'>
                            {
                                (step >= 1 && step !== 4) && <div><h1>Address: </h1><span>{address}</span>
                                    <div className='clock'>
                                        <Clock value={time} size={100} />
                                    </div>
                                </div>
                            }
                            {
                                (step >= 2 && step !== 4) && <div><h1> Time Check: </h1><span>{currentDateTime}</span></div>
                            }
                            {
                                (step >= 2 && step !== 4) && <div style={{ marginTop: 24 }}>
                                    <h1>Password Required:</h1>
                                    <Form.Item
                                        name='password'
                                        label='Password'
                                        rules={[{ required: true, message: 'Please input your password!' }]}
                                    >
                                        <Input.Password className='primary-input' style={{ maxWidth: 250 }} />
                                    </Form.Item>
                                </div>
                            }
                            {
                                step >= 4 && <div><h1> Server Status: </h1><span style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{postSignData?.message} - {postSignData?.time} (RECORDED)</span></div>
                            }

                        </div>

                        {
                            step === 0 &&
                            <Button className='primary-btn' type="primary" onClick={handleGetLocation}
                                loading={getLocationLoading}>
                                Get Current Location
                            </Button>
                        }
                        {
                            step === 1 &&
                            <Button className='primary-btn' type="primary" onClick={handleTimeCheck}
                                loading={false}>
                                Time Check: {currentDateTime}
                            </Button>
                        }

                        {
                            step === 2 &&
                            <Button className='primary-btn' type="primary" htmlType="submit"
                                loading={postSignLoading} >
                                PUNCH
                            </Button>
                        }
                        {
                            step === 4 &&
                            <Button className='primary-btn' type="primary" onClick={() => setStep(0)}
                                loading={false} >
                                Start Over
                            </Button>
                        }
                    </Form>

                </Card>
            </Card>
        </div >
    )
}

export default PunchInComponents