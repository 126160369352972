import { combineReducers } from 'redux'
import login from '../login/reducers'
import announcements from '../announcements/reducers'
import memorandums from '../memorandums/reducers'
import educations from '../educations/reducers'
import references from '../references/reducers'
import dependents from '../dependents/reducers'
import documents from '../documents/reducers'
import medicalHistories from '../medicalHistories/reducers'
import employmentHistories from '../employmentHistories/reducers'
import overtimes from '../overtimes/reducers'
import projects from '../projects/reducers'
import positions from '../positions/reducers'
import performances from '../performances/reducers'
import businessTrips from '../businessTrips/reducers'
import accountabilities from '../accountabilities/reducers'
import fileReports from '../fileReports/reducers'
import employees from '../employee/reducers'
import personalMovements from '../personalMovements/reducers'
import timeRequests from '../timeRequests/reducers'
import timelogs from '../timelogs/reducers'
import processDtr from '../processDtr/reducers'
import disciplinaryActions from '../disciplinaryActions/reducers'
import leaves from '../leaves/reducers'
import holidays from '../holidays/reducers'
import administrators from '../admins/reducers'
import dashboard from '../dashboard/reducers'
import allowanceAllotment from '../allowanceHistories/reducers'

const rootReducer = combineReducers({
  login,
  dashboard,
  educations,
  references,
  employees,
  dependents,
  documents,
  medicalHistories,
  employmentHistories,
  overtimes,
  projects,
  performances,
  businessTrips,
  accountabilities,
  fileReports,
  personalMovements,
  positions,
  timeRequests,
  announcements,
  memorandums,
  timelogs,
  disciplinaryActions,
  leaves,
  administrators,
  processDtr,
  allowanceAllotment,
  holidays
})

export default rootReducer
