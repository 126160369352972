import React from 'react'
import { Route, BrowserRouter, Switch, useRouteMatch } from 'react-router-dom';
import FormComponent from './form'
import './index.scss'
const Index = () => {
    let { path } = useRouteMatch();
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={`${path}`} component={FormComponent} />
            </Switch>
        </BrowserRouter>
    )
}

export default Index