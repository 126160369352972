import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Modal, notification } from 'antd'
import { create, update } from '../actions'
import { ADD, VIEW, NONE } from '@page-actions'
import session from '@session'
import './index.scss'
import moment from 'moment'
import { load as loadProjects } from '../../../admin/projects/actions'
const { Option } = Select

const FormOvertime = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const { updateLoading } = useSelector(state => state.client.educations)
    const projects = useSelector(state => state.admin.projects)
    const userId = session.user().id

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            timeIn: (props.selectedRow && moment(props.selectedRow.timeIn)) || new Date(),
            timeOut: (props.selectedRow && moment(props.selectedRow.timeOut)) || new Date(),
        })

        // if ((props.actionType === ADD) || (props.actionType === VIEW)) {
        //     navigator.geolocation.getCurrentPosition(function (position) {
        //         getGeoCodeReverse(position.coords.latitude, position.coords.longitude)
        //         const currLoc = {
        //             latitude: position.coords.latitude,
        //             longitude: position.coords.longitude
        //         }
        //         setLocation(currLoc)

        //     }, function (error) {
        //         setDisabled(true)
        //     })

        //     async function getGeoCodeReverse(latitude, longitude) {
        //         const address = await geoCodeReverse(latitude, longitude)
        //         // setAddress(address.formatted_address)
        //         // form.setFieldsValue({
        //         //     address: address.formatted_address
        //         // })
        //     }
        // }

        dispatch(loadProjects({
            page: 1,
            limit: 100,
        }))

        // eslint-disable-next-line
    }, [props.selectedRow])

    async function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
            // latitude: location.latitude,
            // longitude: location.longitude
        }

        if (props.actionType === ADD) {
            dispatch(create({ userId, ...submitData })).then(res => {
                if (res.type === 'overtimes/createFulfilled') {
                    props.setActionType(NONE)
                    setLoading(false)
                    form.resetFields()
                    notification.success({
                        message: `Added`,
                        description: 'You have successfully created information.',
                    });
                }
            })
        }

        if (props.actionType === VIEW) {
            const overtimeId = props.selectedRow._id
            dispatch(update({ id: overtimeId, ...submitData })).then(res => {
                if (res.type === 'overtimes/updateFulfilled') {
                    setLoading(false)
                    form.resetFields()
                    notification.success({
                        message: `Added`,
                        description: 'You have successfully updated information.',
                    });
                }

            })
        }
    }

    return (
        <Modal forceRender title={(props.selectedRow && props.selectedRow.company) || 'Add Overtime'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`overtime`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Time In"
                                name="timeIn"
                                rules={[{ required: true, message: 'Please input time in!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                />
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Time Out"
                                name="timeOut"
                                rules={[{ required: true, message: 'Please input time out!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                />
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Reason"
                                name="reason"
                                rules={[{ required: true, message: 'Please input time out!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        {/* <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Current Location"
                                name="address"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col> */}

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Projects"
                                name="projectId"
                                rules={[{ required: true, message: 'Please input project id!' }]}
                            >
                                <Select>
                                    {
                                        projects.getData && projects.getData.data.map((data, index) => {
                                            return (
                                                <Option value={data._id} key={index}>{data.title}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>


                    </Row>
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={updateLoading || loading}    >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}
export default FormOvertime