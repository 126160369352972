import React from 'react'
import { Modal, Tag, Alert } from 'antd'
const AbsentWithoutPayModal = (props) => {
    return (
        <Modal
            title="Absent Without Pay"
            open={props.visible}
            footer={null}
            onCancel={() => props.onCancel()}
            width={710}
        >
            <Alert
                message="ABSENTS (AWOP)"
                description="Automatically removes Holidays, Dates with Approve Leave Requests, and Users (Not working days) based on selected date range."
                type="success"
                showIcon
            />
            <table style={{ width: '100%', overflowX: 'scroll' }}>
                <tbody>
                    {
                        props.data && props.data.map((date, i) => {
                            return (
                                <tr key={i}>
                                    <td ><Tag color="gold">Absent: </Tag>{date}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Modal>
    )
}

export default AbsentWithoutPayModal