import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Form, Select, DatePicker, Card, notification, Alert } from 'antd'
import { loadById, update, create } from '../actions'
import { load as loadEmployees } from '../../employee/actions';
import enumPersonalMovementType from '../../../../utilities/enumPersonalMovementType'
import { ArrowRightOutlined } from '@ant-design/icons'
import './index.scss'
import moment from 'moment'
import { load as loadProjects } from '../../projects/actions'
import { load as loadPositions } from '../../positions/actions'
import session from '@session'
import access from '@access'
import { PAGE_VIEW, PAGE_CREATE } from '@page-actions'
import { useParams, useRouteMatch } from 'react-router-dom';
import Toolbar from '@toolbar'
const { Option } = Select
const FormPersonalMovement = (props) => {
    const [form] = Form.useForm()
    const [pageAction, setPageAction] = useState(PAGE_CREATE)
    const [search, setSearch] = useState('')
    const [selectedUser, setSelectedUser] = useState({})
    const [selectedPosition, setSelectedPosition] = useState({})
    const [type, setType] = useState((props.selectedRow && props.selectedRow.type) || enumPersonalMovementType.PROMOTION)
    const { id } = useParams();
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const employees = useSelector(state => state.admin.employees)
    const positions = useSelector((state) => state.admin.positions)
    const projects = useSelector((state) => state.admin.projects)

    const { getByIdData, getByIdLoading, createLoading, updateLoading } = useSelector(state => state.admin.personalMovements)
    const userId = getByIdData.user && getByIdData.user._id

    useEffect(() => {
        if (path === '/personal-movements/create') {
            setPageAction(PAGE_CREATE)
            form.setFieldsValue({
                type: enumPersonalMovementType.PROMOTION
            })
        }
        else if (path === '/personal-movements/view/:id') {
            setPageAction(PAGE_VIEW)
            dispatch(loadById(id)).then(res => {
                form.resetFields()
                form.setFieldsValue({
                    ...res.payload,
                    dateFrom: res.payload && moment(res.payload.dateFrom),
                    dateTo: res.payload && moment(res.payload.dateTo),
                    salaryFrom: res.payload && res.payload.ratePerMonth,
                })
            })

            if (userId) {
                dispatch(loadEmployees({ page: 1, limit: 50, id: userId, }))
                dispatch(loadProjects({ page: 1, limit: 100, }))
                dispatch(loadPositions({ page: 1, limit: 100, }))

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            dispatch(loadEmployees({ page: 1, limit: 50, search: search, }))
            dispatch(loadPositions({ page: 1, limit: 100, }))
        }, 1000);
        return () => clearTimeout(timerId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);


    function handleSubmit(value) {
        const submitData = {
            postById: session.user().id,
            ...value,
        }

        console.log("props.actionType", props.actionType, submitData)

        if (pageAction === PAGE_CREATE) {

            delete submitData.fromPositionId
            delete submitData.fromProjectId
            dispatch(create({
                ...submitData,
                fromPositionId: selectedUser.position && selectedUser.position._id,
                fromProjectId: selectedUser.project && selectedUser.project._id,
            })).then(res => {
                notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                goBack()
            })

            if (pageAction === PAGE_VIEW) {
                const personalMovementId = props.selectedRow._id
                dispatch(update({ id: personalMovementId, ...submitData })).then(res => {
                    notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                })
            }
        }
    }
    useEffect(() => {
        if (pageAction === PAGE_VIEW) {
            if (!(userId)) return
            dispatch(loadEmployees({
                page: 1,
                limit: 50,
                id: userId,
            }))
        }
        dispatch(loadProjects({
            page: 1,
            limit: 100,
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            dispatch(loadEmployees({
                page: 1,
                limit: 50,
                id: userId,
            }))
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    function selectUser(e) {
        setSelectedUser(e.data)
        form.setFieldsValue({
            fromPositionId: e.data && e.data.position && e.data.position.title,
            fromProjectId: e.data && e.data.project && e.data.project.title,
            fromSalary: e.data && e.data && e.data.ratePerMonth,
            fromEmploymentStatus: e.data && e.data && e.data.employmentStatus,
        })
    }

    function selectPosition(e) {
        setSelectedPosition(e.data)
    }

    function goBack() {
        form.resetFields()
        props.history.goBack()
    }
    const isAccessDisabled = (!access.check('personalMovements', 'edit') && (pageAction === PAGE_VIEW))
    console.log("TYPE", type)
    return (
        <Card bordered={false}>
            <Toolbar
                title='Personal Movements'
                handleBack={() => props.history.goBack()}
            />
            {
                ((pageAction === PAGE_VIEW) && access.check('personalMovements', 'verify')) &&
                <Fragment>
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={24} lg={24} sm={24} xs={24} style={{ marginBottom: 12 }}  >
                            <Alert
                                message={"POSITION, PROJECT, EMPLOYMENT STATUS AND TYPE INFORMATIONS"}
                                description={<span>{'The position, project, position status and type fields are not editable. If you want to change or edit the current information in position or project fields, You must create another record for Personnel Movements. This is for record purposes. '}</span>}
                                type={'success'}
                                showIcon
                                closable
                            />
                        </Col>
                    </Row>
                </Fragment>
            }
            <Form
                name={`personalMovement`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (pageAction === PAGE_CREATE) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select disabled={isAccessDisabled} placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} onChange={(_, e) => selectUser(e)} optionFilterProp="children" loading={employees.getLoading}  >
                                    {
                                        employees.getData.data.map((data, index) => {
                                            return (
                                                <Option key={data._id} value={data._id} data={data} >{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: true, message: 'Please input type!' }]}
                        >
                            <Select disabled={isAccessDisabled || (pageAction === PAGE_VIEW)} onChange={(e, data) => setType(e)} >
                                {
                                    Object.keys(enumPersonalMovementType).map(function (key, index) {
                                        return (
                                            <Option value={enumPersonalMovementType[key]} key={index} >{key}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Center Code"
                            name="centerCode"
                            rules={[{ required: true, message: 'Please input center code!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (enumPersonalMovementType.PROMOTION === type) &&
                            <Card title={`Position Change ${(pageAction === PAGE_VIEW) ? '- (READ ONLY)' : ''}`} style={{ marginBottom: 24 }}>
                                <Row>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="Current Position"
                                            name="fromPositionId"
                                            rules={[{ required: true, message: 'Please input from-position!' }]}
                                        >
                                            <Select disabled>
                                                {
                                                    positions.getData.data.map((item, index) => {
                                                        return (
                                                            <Option value={item._id} data={item} key={index}>{item.title}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                        <strong>Job Level: {(((selectedUser.position && selectedUser.position.jobLevel) || ((props.selectedRow && props.selectedRow.fromPosition) && props.selectedRow.fromPosition.jobLevel))) || 0}</strong>
                                    </Col>
                                    <Col md={4} lg={4} sm={24} xs={24} align='center'>
                                        <ArrowRightOutlined style={{ fontSize: 30, marginTop: 6, color: 'green' }} />
                                    </Col>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="To Position"
                                            name="toPositionId"
                                            rules={[{ required: true, message: 'Please input to-position!' }]}
                                        >
                                            <Select onChange={(_, e) => selectPosition(e)} disabled={(isAccessDisabled || (pageAction === PAGE_VIEW))}>
                                                {
                                                    positions.getData.data.map((item, index) => {
                                                        return (
                                                            <Option disabled={selectedUser.position && selectedUser.position._id === item._id} value={item._id} data={item} key={index}>{item.title}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                        <strong>Job Level: {(((selectedPosition && selectedPosition.jobLevel) || (props.selectedRow && props.selectedRow.toPosition && props.selectedRow.toPosition.jobLevel)))}</strong>
                                    </Col>
                                </Row>
                            </Card>
                        }

                        {
                            (enumPersonalMovementType.LOCATION_TRANSFER === type) &&
                            <Card title={`Project Change ${(pageAction === PAGE_VIEW) ? '- (READ ONLY)' : ''}`} style={{ marginBottom: 24 }}>
                                <Row>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="Current Project"
                                            name="fromProjectId"
                                            rules={[{ required: true, message: 'Please input from-project!' }]}
                                        >
                                            <Select disabled>
                                                {
                                                    projects.getData.data.map((item, index) => {
                                                        return (
                                                            <Option value={item._id} data={item} key={index}>{item.title}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} lg={4} sm={24} xs={24} align='center'>
                                        <ArrowRightOutlined style={{ fontSize: 30, marginTop: 6, color: 'green' }} />
                                    </Col>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="To Project"
                                            name="toProjectId"
                                            rules={[{ required: true, message: 'Please input to-project!' }]}
                                        >
                                            <Select disabled={isAccessDisabled || (pageAction === PAGE_VIEW)}>
                                                {
                                                    projects.getData.data.map((item, index) => {
                                                        return (
                                                            <Option disabled={selectedUser.project && selectedUser.project._id === item._id} value={item._id} data={item} key={index}>{item.title}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        }

                        {
                            (enumPersonalMovementType.SALARY_ADJUSTMENT === type) &&
                            <Card title={`Salary Adjustment ${(pageAction === PAGE_VIEW) ? '- (READ ONLY)' : ''}`} style={{ marginBottom: 24 }}>
                                <Row>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="Salary From"
                                            name="fromSalary"
                                            rules={[{ required: true, message: 'Please input salary from!' }]}
                                        >
                                            <Select disabled>
                                                {
                                                    projects.getData.data.map((item, index) => {
                                                        return (
                                                            <Option value={item._id} data={item} key={index}>{item.title}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} lg={4} sm={24} xs={24} align='center'>
                                        <ArrowRightOutlined style={{ fontSize: 30, marginTop: 6, color: 'green' }} />
                                    </Col>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="Salary To"
                                            name="toSalary"
                                            rules={[{ required: true, message: 'Please input salary to!' }]}
                                        >
                                            <Input type='number' disabled={isAccessDisabled || (pageAction === PAGE_VIEW)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        }

                        {
                            (enumPersonalMovementType.REGULARIZATION === type) &&
                            <Card title={`Employment Status ${(pageAction === PAGE_VIEW) ? '- (READ ONLY)' : ''}`} style={{ marginBottom: 24 }}>
                                <Row>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="Employment Status From"
                                            name="fromEmploymentStatus"
                                            rules={[{ required: true, message: 'Please input employment status from!' }]}
                                        >
                                            <Select disabled >
                                                <Option value={1} key={1} data='Probationary'>Probationary</Option>
                                                <Option value={2} key={2} data='Regular'>Regular</Option>
                                                <Option value={3} key={3} data='Resigned'>Resigned</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} lg={4} sm={24} xs={24} align='center'>
                                        <ArrowRightOutlined style={{ fontSize: 30, marginTop: 6, color: 'green' }} />
                                    </Col>
                                    <Col md={10} lg={10} sm={24} xs={24}>
                                        <Form.Item
                                            label="Employment Status To"
                                            name="toEmploymentStatus"
                                            rules={[{ required: true, message: 'Please input employment status to!' }]}
                                        >
                                            <Select disabled={isAccessDisabled || (pageAction === PAGE_VIEW)}   >
                                                <Option value={1} disabled={selectedUser.employmentStatus === 1}>Probationary</Option>
                                                <Option value={2} disabled={selectedUser.employmentStatus === 2}>Regular</Option>
                                                <Option value={3} disabled={selectedUser.employmentStatus === 3}>Resigned</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        }

                        <Form.Item
                            label="Division Code"
                            name="divisionCode"
                            rules={[{ required: true, message: 'Please input division code!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Property Code"
                            name="propertyCode"
                            rules={[{ required: true, message: 'Please input property code!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            // label="Date From"
                            label="Effective Date"
                            name="dateFrom"
                            rules={[{ required: true, message: 'Please input date from!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Date To"
                            name="dateTo"
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[{ message: 'Please input remarks!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        {/* {
                            (pageAction === PAGE_VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('personalMovements', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('personalMovements', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('personalMovements', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        } */}
                    </Col>
                </Row>
                {
                    ((access.check('personalMovements', 'add') && (pageAction === PAGE_CREATE)) || (access.check('personalMovements', 'edit') && (pageAction === PAGE_VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={getByIdLoading || createLoading || updateLoading} disabled={getByIdLoading || createLoading || updateLoading}  >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Card >
    )
}


export default FormPersonalMovement