import React, { useEffect, useState } from 'react'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Spin, Modal, notification } from 'antd'
import { useSelector } from 'react-redux'
import ClientComponent from './client'
import AdminComponent from './admin'
import SuperAdminComponent from './superadmin'
import LoginComponent from './login/components/index'
import ForgotPasswordComponent from './forgotPassword/components/index'
import ChangePasswordComponent from './resetPassword/components/index'
import { userType } from '@enums'
import session from '@session'
import io from "socket.io-client";
import { getToken } from '../utilities/token';
import { AppContext } from './Context';
import { Route, Switch } from 'react-router-dom'
import Axios from 'axios'

const { CLIENT, ADMIN, SUPERADMIN } = userType
const { REACT_APP_API_BASE_URL, REACT_APP_WEBSOCKET_URL } = process.env

export default function App(props) {
  const { loggedIn } = useSelector(state => state.login)

  const [userType, setUserType] = useState()
  const [isLoading, setIsLoading] = useState()
  const [socket, setSocket] = useState()

  useEffect(() => {
    Axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const errorStatus = error.response.status
        // 400 = BAD REQUEST (DONT LOGOUT AND RETURN MESSAGE)
        const errorExceptionStatus = [400]
        if (session.token()) {
          // if contains token then logout
          // if Unauthorized, direct logout
          if (errorStatus === 401) session.logout()
        }
        if (errorExceptionStatus.indexOf(errorStatus) === -1) {
          const errorMessage = error && error.response && error.response.data && error.response.data.message
          Modal.warning({ title: 'Server Says', content: `${errorMessage} [ TRY AGAIN LATER ].`, okText: 'OK', onOk: () => session.logout(), });
          return Promise.reject(error);
        } else {
          notification.warning({
            message: 'Message',
            description: error.response && error.response.data && error.response.data.message,
            duration: 5
          });
        }

      }
    );
  }, [])

  // Check session token in initial load
  useEffect(() => {
    setIsLoading(true)
    const type = session.user() && session.user().type
    setUserType(type)
    setIsLoading(false)
  }, [])

  // Check state when logging in
  useEffect(() => {
    if (loggedIn) {
      // const type = props.loggedData && props.loggedData.user.type
      // setUserType(type)
      window.location.reload();
    }
  }, [loggedIn])

  useEffect(() => {
    console.log("API URL:", REACT_APP_API_BASE_URL)
    console.log("WS URL:", REACT_APP_WEBSOCKET_URL)
    // Initialized Socket IO
    const socketIO = io(REACT_APP_WEBSOCKET_URL, { query: { token: getToken() } });
    setSocket(socketIO)
  }, [])


  return isLoading ?
    <Spin spinning /> : userType ?
      <AppContext.Provider value={{
        socket
      }}>
        <DisplayComponent userType={userType} />
      </AppContext.Provider>
      : <OpenRouteComponent {...props} />
}

function OpenRouteComponent() {
  return (
    <Switch>
      <Route exact={true} path={'/'} component={LoginComponent} key='login' />
      <Route path={'/forgot-password'} component={ForgotPasswordComponent} key='forgot-password' />
      <Route path={'/change-password'} component={ChangePasswordComponent} key='change-password' />
    </Switch>
  )
}

function DisplayComponent(props) {
  switch (props.userType) {
    case CLIENT:
      return <ClientComponent
      //  socket={this.socket} 
      />
    case ADMIN:
      return <AdminComponent />
    case SUPERADMIN:
      return <SuperAdminComponent
      //  socket={this.socket}
      />
    default:
      return <div>aaaa</div>
  }
}
