import React, { useState, useEffect, useContext } from 'react'
import { Card, Tabs, notification, Button, Progress, Spin, Divider } from 'antd'
import Toolbar from '@toolbar'
import { useDispatch } from 'react-redux'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { upload } from '../../../../utilities/filesController'
import Papa from 'papaparse'
import './index.scss'
import { uploadEmployeeMasterfile } from '../actions'
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../Context'
const UploadMasterfile = (props) => {
    const context = useContext(AppContext);
    const [listOfObject, setListOfObject] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isUploadSuccess, setIsUploadSucess] = useState(false)
    const [uploadedCount, setUploadedCount] = useState(0)
    const [employeeData, setEmployeeData] = useState([])
    const [connectionId, setConnectionId] = useState('')
    const [uploadEmployeeSuccess, setUploadEmployeeSuccess] = useState(false)
    const [uploadEmployeeLoading, setUploadEmployeeLoading] = useState(false)
    const [uploadEmployeeStatus, setUploadEmployeeStatus] = useState({ count: 0, total: 0, percentage: 0 })
    const dispatch = useDispatch();

    function getFile(arrayObj) {
        setListOfObject(arrayObj)
    }

    useEffect(() => {
        const newConId = uuidv4();
        setConnectionId(newConId)
        context.socket.on(`upload-masterfile-.${newConId}`, data => {
            setUploadEmployeeStatus(data)
            if (data.percentage === 100) {
                setUploadEmployeeLoading(false)
                setUploadEmployeeSuccess(true)
                notification.info({ message: `Uploaded!`, description: "Succesfully uploaded", });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getFileEmployeeCSV(e) {
        Papa.parse(e[0], {
            header: true,
            complete: function (results) {
                console.log("RESULTS", results)
                setEmployeeData([...results.data])
                // // remove data that does not have urefno
                // const removedDataWithoutUrefNo = results.data.filter(obj => Object.keys(obj).includes("urefno")).filter(data => data.urefno)
                // // return only urefno and outhdep
                // const trimmedData = removedDataWithoutUrefNo.map(item => {
                //     return {
                //         urefno: item.urefno,
                //         uoccname: item.uoccname,
                //         uoccpers: item.uoccpers,
                //         uocctel1: item.uocctel1,
                //         uocctel2: item.uocctel2,
                //         ubtname: item.ubtname,
                //         ubaddln1: item.ubaddln1,
                //         ubaddln2: item.ubaddln2,
                //         ubaddln3: item.ubaddln3,
                //         ubaddln4: item.ubaddln4,
                //         soaamt: parseFloat(item.uothdep)
                //     };
                // })
                // self.setState({ csvWebClientParsedFileArray: trimmedData })
            }
        });
        console.log("eeeee", e)
    }

    function uploadFile(index = 0) {
        return new Promise((resolve, reject) => {
            if (index !== listOfObject.length) {
                const item = listOfObject[index]
                upload(item, item.name, item.uploadDir).then(data => {
                    index = index + 1
                    setUploadedCount(index)
                    uploadFile(index)
                }).catch(err => {
                    return notification.error({ message: `Failed to upload image`, description: err, });
                })
            } else {
                notification.info({ message: `Uploaded!`, description: "Succesfully uploaded", });
                setIsUploadSucess(true)
                setIsLoading(false)
                return resolve(true)
            }
        })
    }

    function handleUpload() {
        setIsLoading(true)
        if (listOfObject.length) {
            uploadFile(0).finally(() => setIsLoading(false))
        }
    }

    function handleUploadEmployeeMasterfile() {
        setUploadEmployeeLoading(true)
        dispatch(uploadEmployeeMasterfile({ data: employeeData, connectionId: connectionId }))
    }

    return (
        <Card bordered={false}>
            <Toolbar
                title='Upload'
                handleBack={() => props.history.goBack()}
            />
            <Tabs>
                <Tabs.TabPane tab="Upload Employees" key="1">
                    <Spin spinning={uploadEmployeeLoading} style={{ marginBottom: 12 }}>
                        {
                            !uploadEmployeeSuccess ? <CustomDropzoneMultiple
                                autoRename={false}
                                fileName='none'
                                uploadDir='none'
                                height={200}
                                width={200}
                                maxFiles={0}
                                type='CSV'
                                getFile={file => { getFileEmployeeCSV(file) }}
                            /> : <h4>Successfully Uploaded! <a href={() => false} onClick={() => {
                                setUploadEmployeeSuccess(false)
                                setUploadEmployeeStatus({ count: 0, total: 0, percentage: 0 })
                            }}>Click here to upload again!</a></h4>
                        }
                    </Spin>
                    <Divider />
                    <h1>{uploadEmployeeStatus.count} / {uploadEmployeeStatus.total}</h1>
                    <Progress percent={(uploadEmployeeStatus.percentage || 0).toFixed(2)} />
                    {
                        !uploadEmployeeSuccess && <Button type="primary" onClick={handleUploadEmployeeMasterfile} loading={isLoading} style={{ marginTop: 12 }}>Upload Employee</Button>
                    }
                </Tabs.TabPane>
                <Tabs.TabPane tab="Upload Payslip" key="2">
                    <Spin spinning={isLoading} style={{ marginBottom: 12 }}>
                        {
                            !isUploadSuccess ? <CustomDropzoneMultiple
                                autoRename={false}
                                fileName='payslip'
                                uploadDir='payslip'
                                height={200}
                                width={200}
                                maxFiles={0}
                                type='PDF'
                                getFile={file => { getFile(file) }}

                            /> : <h4>Successfully Uploaded! <a href={() => false} onClick={() => {
                                setIsUploadSucess(false)
                                setUploadedCount(0)
                                setListOfObject([])
                            }}>Click here to upload again!</a></h4>
                        }
                    </Spin>
                    <Divider />
                    {!isUploadSuccess && <h1>{uploadedCount} / {listOfObject.length}</h1>}
                    <Progress percent={(((uploadedCount / listOfObject.length) * 100) || 0).toFixed(2)} />
                    {!isUploadSuccess && <Button type="primary" onClick={handleUpload} loading={isLoading} style={{ marginTop: 12 }}>Upload Payslip</Button>}
                </Tabs.TabPane>

            </Tabs>
        </Card>
    )
}

export default UploadMasterfile