import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Tag, Menu, Space, Card, Dropdown, Popconfirm, Avatar, Button, notification, Tooltip } from 'antd'
import { load, remove } from '../actions'
import { ImagePresignedUrl } from '@renderer'
import moment from 'moment'
import session from '@session'
import Toolbar from '@toolbar'
import access from '@access'
import Permissions from '@permissions'
import Papa from 'papaparse'
import { useRouteMatch } from 'react-router-dom';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { load as loadProjects } from '../../projects/actions'
import { load as loadPositions } from '../../positions/actions'
import './index.scss'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        employmentStatus: 0,
        projectId: '',
        positionId: '',
        sort: 'desc',
        search: '',
    })
    const prevPagination = useRef(pagination)
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const { getData, getLoading } = useSelector(state => state.admin.employees)
    const projects = useSelector(state => state.admin.projects)
    const positions = useSelector((state) => state.admin.positions)

    useEffect(() => {
        if (prevPagination.current !== pagination) dispatch(load(pagination))
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])


    useEffect(() => {
        dispatch(loadProjects())
        dispatch(loadPositions())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        setTableData([...getData.data])
        return () => { }
    }, [getData])

    function handleDelete(id) {
        const postById = session.user().id
        dispatch(remove(id, postById)).then(res => {
            if (res.type === 'employees/removeFulfilled') {
                notification.success({ message: 'Deleted', description: 'You have successfully deleted a data.' });
                dispatch(load(pagination))
            }
        })
    }

    function handleView(data) {
        props.history.push(`/employees/view/${data._id}`)
    }

    function handleDownload() {
        const exportData = tableData.map(data => {
            return {
                "Created Date": moment(data.createdAt).format('LLLL'),
                "Name": `${data.firstName} ${data.lastName}`,
                "Account Number": data.accountNumber,
                "Address": data.address,
                "Allowance": data.allowance,
                "Birthday": data.birthday,
                "Center Code": data.centerCode,
                "Citizenship": data.citizenship,
                "Contact": data.contact,
                "Date Hired": data.dateHired,
                "Date Resigned": data.dateResigned,
                "Division Code": data.divisionCode,
                "Email": data.email,
                "Employee Number": data.employeeNumber,
                "Employment Status": data.employmentStatus,
                "Gender": data.gender,
                "Marital Status": data.maritalStatus,
                "Pag Ibig Number": data.pagIbigNumber,
                "Pag Ibig Prem": data.pagIbigPrem,
                "Phil Health Number": data.philHealthNumber,
                "Position": data.position && data.position.title,
                "Position Date": data.positionDate,
                "Project": data.project && data.project.title,
                "Property Code": data.propertyCode,
                "Rate Per Hour": data.ratePerHour,
                "Religion": data.religion,
                "Section Code": data.sectionCode,
                "Sick Leave Days": data.sickLeaveDays,
                "SSS Number": data.sssNumber,
                "Tax Status": data.taxStatus,
                "Tin": data.tin,
                "Vacation Leave Days": data.vacationLeaveDays,
                "Working Days Per Month": data.workingDaysPerMonth,
            }
        })
        var csv = Papa.unparse(exportData);
        var unix = Date.now()
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `employees_${unix}.csv`);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `employees_${unix}.csv`);
        tempLink.click();
    }

    function empStatusLabel(value) {
        switch (value) {
            case 0:
                return 'All'
            case 1:
                return 'Probationary'
            case 2:
                return 'Regular'
            case 3:
                return 'Resigned'
            default:
                break;
        }
    }

    function getProjectLabel(id) {
        if (id) return projects.getData.data.find(data => data._id === id).title
        else return 'All'
    }

    function getPositionLabel(id) {
        if (id) return positions.getData.data.find(data => data._id === id).title
        else return 'All'
    }

    const dataSource = tableData
    const columns = [

        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (
                    <div className='col-image'>
                        <Avatar size={44} src={<ImagePresignedUrl alt='user-profile' src={data.image && data.image[0] && data.image[0].url} objectFit='cover' />} />
                    </div>
                )
            }
        },
        {
            title: 'Employee Number',
            dataIndex: 'employeeNumber',
            key: 'employeeNumber',
            responsive: ['lg', 'md', 'sm'],
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return <span className='name'>{data.firstName} {data.lastName}</span>
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            responsive: ['lg', 'md', 'sm'],
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            responsive: ['lg', 'md', 'sm'],
            render: (item, data) => {
                return (
                    <div className='col-image'>
                        <span>{item && item.title}</span>
                    </div>
                )
            }
        },
        {
            title: 'Job Level',
            dataIndex: 'jobLevel',
            key: 'jobLevel',
            responsive: ['lg', 'md', 'sm'],
            width: 200,
            render: (item, data) => {
                return (data.position && data.position.jobLevel)
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (item, data) => {
                return (
                    <div className='col-image'>
                        <Tag color="#108ee9">{empStatusLabel(data.employmentStatus)}</Tag>
                    </div>
                )
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            responsive: ['lg', 'md', 'sm'],
            render: (item, data) => {
                return (moment(data.createdAt).format('LLLL'))
            }
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <Space>
                        <Button type='primary' size='small' onClick={() => handleView(data)}  >
                            View
                        </Button>
                        {(access.check('employeeMasterfile', 'delete') && data.status === 1) &&
                            <Button type='danger' size='small'>
                                <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                    Delete
                                </Popconfirm>
                            </Button>
                        }
                    </Space>
                )
            }
        },
    ];
    return (
        <Layout className='page' >
            <Permissions module='employeeMasterfile' role='view' >
                <Card bordered={false}>
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title='Employee Masterfile'
                                handleAdd={() => props.history.push(`${path}/create`)}
                                removeAdd={!access.check('employeeMasterfile', 'add')}
                                handleDownload={() => handleDownload()}
                                handleSearch={(search) => setPagination({ ...pagination, search })}
                                customAdd={[
                                    {
                                        component: <Tooltip title="Upload to Masterfile"><Button onClick={() => props.history.push(`${path}/upload`)} className='btn-circle' shape="circle" icon={<UploadOutlined />} /></Tooltip>
                                    }
                                ]}
                                // handleSort={(sort) => setPagination({ ...pagination, sort })}
                                customFilter={[
                                    {
                                        component: <Dropdown key={1} className='dropdown-filter' overlay={(
                                            <Menu items={[
                                                { label: 'All', key: 'all', onClick: () => { setPagination({ ...pagination, projectId: undefined }) } },
                                                ...projects.getData.data.map(project => {
                                                    return { label: project.title, key: `project_${project._id}`, onClick: () => { setPagination({ ...pagination, projectId: project._id }) } }
                                                })
                                            ]} />
                                        )} placement="bottom">
                                            <Button>
                                                <span className='fade'>Project </span><span className='label'>{getProjectLabel(pagination.projectId)}</span><b><DownOutlined /></b>
                                            </Button>
                                        </Dropdown>
                                    },
                                    {
                                        component: <Dropdown key={2} className='dropdown-filter' overlay={(
                                            <Menu items={[
                                                { label: 'All', key: 'all', onClick: () => { setPagination({ ...pagination, positionId: undefined }) } },
                                                ...positions.getData.data.map(position => {
                                                    return { label: position.title, key: `position_${position._id}`, onClick: () => { setPagination({ ...pagination, positionId: position._id }) } }
                                                })
                                            ]} />
                                        )} placement="bottom">
                                            <Button>
                                                <span className='fade'>Position </span><span className='label'>{getPositionLabel(pagination.positionId)}</span><b><DownOutlined /></b>
                                            </Button>
                                        </Dropdown>
                                    }
                                ]}
                                // handleStatus={(status) => setPagination({ ...pagination, status })}
                                statusArray={[
                                    { id: 0, label: 'All' },
                                    { id: 1, label: 'For Approval' },
                                    { id: 2, label: 'Approved' },
                                    { id: 5, label: 'Cancelled' },
                                    { id: 6, label: 'Rejected' },
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination
                                        //  defaultCurrent={pagination.page}
                                        onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>

            </Permissions>
        </Layout >
    )
}

export default List