
import { createSlice } from "@reduxjs/toolkit";

const resetPassword = createSlice({
  name: 'resetPassword',
  initialState: {
    resetPasswordLoading: false,
    resetPasswordFulfilled: false,
    resetPasswordRejected: false,
    resetPasswordData: {},
    checkSessionLoading: false,
    checkSessionFulfilled: false,
    checkSessionRejected: false,
    checkSessionData: {}
  },
  reducers: {
    postResetPassword(state, action) {
      return {
        ...state,
        resetPasswordLoading: true,
        resetPasswordFulfilled: false,
        resetPasswordRejected: false
      }
    },
    postResetPasswordFulfilled(state, action) {
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordFulfilled: true,
        resetPasswordData: action.payload
      }
    },
    postResetPasswordRejected(state, action) {
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordRejected: true
      }
    },
    postCheckSession(state, action) {
      return {
        ...state,
        checkSessionLoading: true,
        checkSessionFulfilled: false,
        checkSessionRejected: false
      }
    },
    postCheckSessionFulfilled(state, action) {
      return {
        ...state,
        checkSessionLoading: false,
        checkSessionFulfilled: true,
        checkSessionData: action.payload
      }
    },
    postCheckSessionRejected(state, action) {
      return {
        ...state,
        checkSessionLoading: false,
        checkSessionRejected: true
      }
    },
  }
});

export const actions = resetPassword.actions;

export default resetPassword.reducer;