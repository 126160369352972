import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Space, Button, Popconfirm, Card, notification } from 'antd'
import { load, remove } from '../actions'
import './index.scss'
import Toolbar from '@toolbar'
import access from '@access'
import Permissions from '@permissions'
import { useRouteMatch } from 'react-router-dom';

import moment from 'moment';
const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [search, setSearch] = useState('')
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const prevPagination = useRef(pagination)
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const { getData, getLoading } = useSelector(state => state.admin.holidays)

    useEffect(() => {
        if (prevPagination.current !== pagination) dispatch(load(pagination))
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        setTableData([...getData.data])
        return () => { }
    }, [getData])

    function handleDelete(id) {
        dispatch(remove(id)).then(res => {
            if (res.type === 'holidays/removeFulfilled') {
                notification.success({ message: 'Deleted', description: 'You have successfully deleted a data.' });
                dispatch(load(pagination))
            }
        })
    }

    useEffect(() => {
        if (search) {
            const delayDebounceFn = setTimeout(() => {
                setPagination(prevState => ({ ...prevState, search }));
            }, 1300)
            return () => clearTimeout(delayDebounceFn)
        } else dispatch(load({ ...pagination, search: '' }))
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    function handleView(data) {
        props.history.push(`/holidays/view/${data._id}`)
    }
    const dataSource = tableData
    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (item, data) => {
                return (moment(item).format('LL'))
            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Created By',
            dataIndex: 'postBy',
            key: 'postBy',
            render: (item) => {
                return (<span>{`${item.firstName} ${item.lastName}`}</span>)
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item) => {
                return (<span>{moment(item).format('LL')}</span>)
            }
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <Space>
                        <Button type='primary' size='small' onClick={() => handleView(data)}  >
                            View
                        </Button>
                        {(access.check('holidays', 'delete')) &&
                            <Button type='danger' size='small'>
                                <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                    Delete
                                </Popconfirm>
                            </Button>
                        }
                    </Space>
                )
            }
        },


    ];
    return (
        <Layout className='page'>
            <Permissions module='holidays' role='view' >
                <Card bordered={false}>
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title='Holidays'
                                handleAdd={() => props.history.push(`${path}/create`)}
                                removeAdd={!access.check('holidays', 'add')}
                                // handleDownload={() => handleDownload()}
                                handleSearch={(search) => setSearch(search)}
                                handleSort={(sort) => setPagination({ ...pagination, sort })}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Permissions>
        </Layout>
    )
}

export default List