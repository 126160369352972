import React from 'react'
import { Modal, Tag } from 'antd'

const LatesModal = (props) => {
    return (
        <Modal
            title="Lates"
            open={props.visible}
            footer={null}
            onCancel={() => props.onCancel()}
            width={710}
        >
            <table style={{ width: '100%', overflowX: 'scroll' }}>
                <tbody>
                    {
                        props.data && props.data.map((data, i) => {
                            return (
                                <tr key={i}>
                                    <td ><Tag color="green">BASE</Tag>{data.base}</td>
                                    <td ><Tag color="gold">IN</Tag> {data.timeIn}</td>
                                    <td style={{ color: 'red', fontWeight: 'bold' }}>{data.diff.hour}:{data.diff.minute} hrs</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Modal>
    )
}

export default LatesModal