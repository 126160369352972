import React, { useEffect } from 'react'
import { Layout, Form, Modal } from 'antd'
import './index.scss'

const FormDisciplinaryAction = (props) => {
    const [form] = Form.useForm()

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
        })
        // eslint-disable-next-line
    }, [props.selectedRow])


    return (
        <Modal forceRender title={(props.selectedRow && props.selectedRow.company) || 'Add DisciplinaryAction'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`disciplinaryAction`}
                    form={form}
                >
                </Form>
            </Layout>
        </Modal>
    )
}

export default FormDisciplinaryAction