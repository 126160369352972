import React from 'react'
import { Layout, Card, Tabs } from 'antd'
import Permissions from '@permissions'
import AllRecords from './allRecords'
import ForApproval from './forApproval'
import './index.scss'

const List = (props) => {
    const items = [
        {
            key: '1',
            label: 'All Records',
            children: <AllRecords />,
        },
        {
            key: '2',
            label: 'For Approval',
            children: <ForApproval />,
        }
    ];
    return (
        <Layout className='page' >
            <Permissions module='timelogs' role='view' >
                <Card bordered={false}>
                    <Tabs defaultActiveKey="1" items={items} />
                </Card>
            </Permissions>

        </Layout>
    )
}

export default List