import { createSlice } from "@reduxjs/toolkit";

const divisions = createSlice({
    name: 'settings',
    initialState: {
        getLoading: false,
        getSuccess: false,
        getRejected: false,
        getData: {
            data: []
        },
        updateLoading: false,
        updateSuccess: false,
        updateRejected: false,

    },
    reducers: {
        get(state, action) {
            return {
                ...state,
                getLoading: true,
                getSuccess: false,
                getRejected: false
            }
        },
        getFulfilled(state, action) {
            return {
                ...state,
                getLoading: false,
                getSuccess: true,
                getData: action.payload
            }
        },
        getRejected(state, action) {
            return {
                ...state,
                getLoading: false,
                getRejected: true
            }
        },
        update(state, action) {
            return {
                ...state,
                updateLoading: true,
                updateSuccess: false,
                updateRejected: false
            }
        },
        updateFulfilled(state, action) {
            return {
                ...state,
                updateLoading: false,
                updateSuccess: true,
                updateData: action.payload
            }
        },
        updateRejected(state, action) {
            return {
                ...state,
                updateLoading: false,
                updateRejected: true
            }
        },
    }
});

export const actions = divisions.actions;

export default divisions.reducer;