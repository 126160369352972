import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Form, Select, DatePicker, Card, notification, Alert, Modal } from 'antd'
import { loadById, update, create } from '../actions'
import { load as loadEmployees } from '../../employee/actions';
import './index.scss'
import moment from 'moment'
import { load as loadProjects } from '../../projects/actions'
import access from '@access'
import { PAGE_VIEW, PAGE_CREATE } from '@page-actions'
import { useParams, useRouteMatch } from 'react-router-dom';
import Toolbar from '@toolbar'
import { dateDiff } from '@time-helper';
const { RangePicker } = DatePicker;

const { Option } = Select
const FormOvertime = (props) => {
    const [form] = Form.useForm()
    const [pageAction, setPageAction] = useState(PAGE_CREATE)
    const [search, setSearch] = useState('')
    const { id } = useParams();
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const employees = useSelector(state => state.admin.employees)
    const projects = useSelector(state => state.admin.projects)
    const { getByIdData, getByIdLoading, createLoading, updateLoading } = useSelector(state => state.admin.overtimes)
    const userId = getByIdData.user && getByIdData.user._id

    useEffect(() => {
        if (path === '/overtimes/create') setPageAction(PAGE_CREATE)
        else if (path === '/overtimes/view/:id') {
            setPageAction(PAGE_VIEW)
            dispatch(loadById(id)).then(res => {
                form.resetFields()
                form.setFieldsValue({
                    ...res.payload,
                    time: [
                        res.payload && moment(res.payload.timeIn),
                        res.payload && moment(res.payload.timeOut),
                    ],
                    totalHours: dateDiff(new Date(res.payload.timeIn), new Date(res.payload.timeOut)).hour
                })
            })

            if (userId) dispatch(loadEmployees({ page: 1, limit: 50, id: userId, }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            dispatch(loadEmployees({ page: 1, limit: 50, search: search, }))
            dispatch(loadProjects({ page: 1, limit: 50, search: search, }))
        }, 1000);
        return () => clearTimeout(timerId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    function handleSubmit(value) {
        if (dateDiff(new Date(value.time[0]), new Date(value.time[1])).hour > 4) {
            return Modal.error({
                title: 'Oppps!',
                content: 'Unable to submit your request, You exceeded the maximum of 4 Hrs.',
            });
        }

        const submitData = { ...value, timeIn: value.time[0], timeOut: value.time[1] }
        delete submitData.totalHours
        delete submitData.time
        if (pageAction === PAGE_CREATE) {
            dispatch(create({ ...submitData })).then(res => {
                if (res.type === 'overtimes/createFulfilled') {
                    notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                    goBack()
                }
            })
        } else if (pageAction === PAGE_VIEW) {
            dispatch(update({ id, ...submitData })).then(res => {
                if (res.type === 'overtimes/updateFulfilled') {
                    notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                    goBack()
                }
            })
        } else {
            notification.warning({ message: 'Opppps!', description: `Can't Submit Data.` });
        }
    }

    function goBack() {
        form.resetFields()
        props.history.goBack()
    }
    function range(start, end) {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }
    function disabledTime() {
        return {
            disabledHours: () => range(0, 18),
        };
    }

    function onChangeDate(value) {
        form.setFieldsValue({
            totalHours: dateDiff(new Date(value[0]), new Date(value[1])).hour
        })
    }
    const isAccessDisabled = (!access.check('overtimes', 'edit') && (pageAction === PAGE_VIEW))
    return (
        <Card bordered={false}>
            <Toolbar
                title='Overtimes'
                handleBack={() => props.history.goBack()}
            />
            <Alert
                message="Allowable Overtimes"
                description="You are allowed to file an overtime requests from 6PM - 10PM (18-22) a total MAX of 4HRS "
                type="info"
                showIcon
            />
            <Form
                name={`overtime`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
                style={{ marginTop: 24 }}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (pageAction === PAGE_CREATE) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={employees.getLoading}>
                                    {
                                        employees.getData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>

                        <Form.Item
                            label="Time In - Out"
                            name="time"
                            rules={[{ required: true, message: 'Please input time in - out!' }]}
                        >
                            <RangePicker showTime style={{ padding: '12px 11px 4px 20px', width: '100%' }}
                                disabledTime={disabledTime} onChange={onChangeDate}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Total Hours"
                            name="totalHours"   >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="Reasons"
                            name="reason"
                            rules={[{ message: 'Please input reasons!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[{ message: 'Please input remarks!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Projects"
                            name="projectId"
                            rules={[{ required: true, message: 'Please input project id!' }]}
                        >
                            <Select disabled={isAccessDisabled}>
                                {
                                    projects.getData.data.map((data, index) => {
                                        return (
                                            <Option value={data._id} key={index}>{data.title}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                        {
                            (pageAction === PAGE_VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('overtimes', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('overtimes', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('overtimes', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                {
                    ((access.check('overtimes', 'add') && (pageAction === PAGE_CREATE)) || (access.check('overtimes', 'edit') && (pageAction === PAGE_VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={getByIdLoading || createLoading || updateLoading} disabled={getByIdLoading || createLoading || updateLoading}  >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Card>
    )
}

export default FormOvertime