
import { createSlice } from "@reduxjs/toolkit";

const timelogs = createSlice({
  name: 'timelogs',
  initialState: {
    getLoading: false,
    getSuccess: false,
    getRejected: false,
    getData: {
      data: []
    },
    getTmsLoading: false,
    getTmsSuccess: false,
    getTmsRejected: false,
    getTmsData: {
      data: []
    },
    getByIdLoading: false,
    getByIdSuccess: false,
    getByIdRejected: false,
    getByIdData: {},
    updateLoading: false,
    updateSuccess: false,
    updateRejected: false,
    createLoading: false,
    createSuccess: false,
    createRejected: false,
    removeLoading: false,
    removeSuccess: false,
    removeRejected: false,
    approveLoading: false,
    approveSuccess: false,
    approveRejected: false,
    approveData: {},
  },
  reducers: {
    getTimelogs(state, action) {
      return {
        ...state,
        getLoading: true,
        getSuccess: false,
        getRejected: false
      }
    },
    getTimelogsFulfilled(state, action) {
      return {
        ...state,
        getLoading: false,
        getSuccess: true,
        getData: action.payload
      }
    },
    getTimelogsRejected(state, action) {
      return {
        ...state,
        getLoading: false,
        getRejected: true
      }
    },
    getTimelogById(state, action) {
      return {
        ...state,
        getByIdLoading: true,
        getByIdSuccess: false,
        getByIdRejected: false
      }
    },
    getTimelogByIdFulfilled(state, action) {
      return {
        ...state,
        getByIdLoading: false,
        getByIdSuccess: true,
        getByIdData: action.payload
      }
    },
    getTimelogByIdRejected(state, action) {
      return {
        ...state,
        getByIdLoading: false,
        getByIdRejected: true
      }
    },
    createTimelog(state, action) {
      return {
        ...state,
        createLoading: true,
        createSuccess: false,
        createRejected: false
      }
    },
    createTimelogFulfilled(state, action) {
      return {
        ...state,
        createLoading: false,
        createSuccess: true,
        createData: action.payload
      }
    },
    createTimelogRejected(state, action) {
      return {
        ...state,
        createLoading: false,
        createRejected: true
      }
    },
    updateTimelog(state, action) {
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
        updateRejected: false
      }
    },
    updateTimelogFulfilled(state, action) {
      return {
        ...state,
        updateLoading: false,
        updateSuccess: true,
        updateData: action.payload
      }
    },
    updateTimelogRejected(state, action) {
      return {
        ...state,
        updateLoading: false,
        updateRejected: true
      }
    },
    removeTimelog(state, action) {
      return {
        ...state,
        removeLoading: true,
        removeSuccess: false,
        removeRejected: false
      }
    },
    removeTimelogFulfilled(state, action) {
      return {
        ...state,
        removeLoading: false,
        removeSuccess: true,
        removeData: action.payload
      }
    },
    removeTimelogRejected(state, action) {
      return {
        ...state,
        removeLoading: false,
        removeRejected: true
      }
    },
    getTimelogsTms(state, action) {
      return {
        ...state,
        getTmsLoading: true,
        getTmsSuccess: false,
        getTmsRejected: false
      }
    },
    getTimelogsTmsFulfilled(state, action) {
      return {
        ...state,
        getTmsLoading: false,
        getTmsSuccess: true,
        getTmsData: action.payload
      }
    },
    getTimelogsTmsRejected(state, action) {
      return {
        ...state,
        getTmsLoading: false,
        getTmsRejected: true
      }
    },
    approveTimelog(state, action) {
      return {
        ...state,
        approveLoading: true,
        approveSuccess: false,
        approveRejected: false
      }
    },
    approveTimelogFulfilled(state, action) {
      return {
        ...state,
        approveLoading: false,
        approveSuccess: true,
        approveData: action.payload
      }
    },
    approveTimelogRejected(state, action) {
      return {
        ...state,
        approveLoading: false,
        approveRejected: true
      }
    },

  }
});

export const actions = timelogs.actions;

export default timelogs.reducer;