import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Layout, Row, Col, Form, Input, notification, Card, Divider, Modal } from 'antd'
import { update, load } from '../actions'
import './index.scss'
import { upload } from '../../../../utilities/filesController'
import { CustomDropzone } from '../../../../utilities/customDropzone'

const WebsiteComponent = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const { updateLoading } = useSelector(state => state.superadmin.settings)
    const [formData, setFormData] = useState({
        logo: '', // /website/1234
        login_bg_image: '', // /website/1234
        under_maintenance: false
    })
    const [file, setFile] = useState({
        logo: [],
        login_bg_image: []
    })
    useEffect(() => {
        dispatch(load()).then(res => {
            const { value } = res.payload
            setFormData({
                ...formData,
                logo: value.logo,
                login_bg_image: value.login_bg_image,
                under_maintenance: value.under_maintenance
            })
            setFile({
                logo: value.logo,
                login_bg_image: value.login_bg_image,
            })
            form.setFieldsValue({ ...value })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function onSubmit(values) {
        setLoading(true)
        Promise.all([
            uploadLogo(),
            uploadLoginBgImage()
        ]).then((res) => {
            console.log("RESSS", res)
            const submitData = {
                name: 'website',
                value: { ...formData, logo: res[0].url, login_bg_image: res[1].url, ...values }
            }
            dispatch(update({ ...submitData })).then(res => {
                if (res.type === 'websites/updateFulfilled') {
                    notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                }
            })
        }).finally(() => setLoading(false))
    }

    function uploadLogo() {
        return new Promise((resolve, reject) => {
            if (file.logo) {
                upload(file.logo, file.logo.name, 'website').then(data => {
                    return resolve(data)
                }).catch(err => {
                    Modal.error({
                        title: 'This is an error message for uploading images',
                        content: err,
                    });
                    return reject()
                })
            } else {
                return resolve({ url: formData.logo })
            }
        })
    }

    function uploadLoginBgImage() {
        return new Promise((resolve, reject) => {
            if (file.login_bg_image) {
                upload(file.login_bg_image, file.login_bg_image.name, 'website').then(data => {
                    return resolve(data)
                }).catch(err => {
                    Modal.error({
                        title: 'This is an error message for uploading images',
                        content: err,
                    });
                    return reject()
                })
            } else {
                return resolve({ url: formData.login_bg_image })
            }
        })
    }

    const onFinishFailed = () => {
        notification.warning({ message: 'Opppps!', description: 'See the validations below.' });
    };

    return (
        <Layout className='form'>
            <Card bordered={false}>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row gutter={32}>
                        <Col lg={24} md={24} xs={24} sm={24}>
                            <h6>Website Settings</h6>
                            <Form.Item
                                label='Website Name'
                                name="app_name"
                                rules={[{ required: true, message: 'Please input website name!' }]}
                            >
                                <Input className='user-input' placeholder='MyApp' />
                            </Form.Item>
                            <Form.Item
                                label='Website Title'
                                name="title"
                                rules={[{ required: true, message: 'Please input website title!' }]}
                            >
                                <Input className='user-input' placeholder='My Application' />
                            </Form.Item>
                        </Col>
                        <Col lg={24} md={24} xs={24} sm={24} style={{ margin: '10px 0' }}>
                            <h6>Logo</h6>
                            <CustomDropzone
                                fileName='settings'
                                uploadDir='settings'
                                height={200}
                                width={200}
                                value={file.logo}
                                getFile={e => setFile({ ...file, logo: e })}
                            />
                        </Col>
                        <Col lg={24} md={24} xs={24} sm={24} style={{ margin: '10px 0' }}>
                            <h6>Login Background Image</h6>
                            <CustomDropzone
                                fileName='settings'
                                uploadDir='settings'
                                height={200}
                                width={200}
                                value={file.login_bg_image}
                                getFile={e => setFile({ ...file, login_bg_image: e })}
                            />
                        </Col>
                    </Row>
                    <Divider />
                    {/* <Row gutter={32} >
                        <Col lg={24} md={24} xs={24} sm={24}>
                            <h6>Under Maintenance</h6>
                            <Switch checked={formData.under_maintenance} onChange={(e) => setFormData({ ...formData, under_maintenance: e })} style={{ marginBottom: 12 }} />
                        </Col>
                        <Col lg={24} md={24} xs={24} sm={24}>
                            <Form.Item
                                label='Maintenance Message'
                                name="maintenance_message"
                                rules={[{ required: true, message: 'Please input your maintenance message!' }]}
                            >
                                <Input className='user-input' placeholder='Website under maintenance, please comeback later!' disabled={!formData.under_maintenance} />
                            </Form.Item>

                        </Col>
                    </Row> */}
                    <Row>
                        <Col lg={24} md={24} xs={24} sm={24} align='right'>
                            <Form.Item >
                                <Button type="primary" htmlType="submit" className='btn-submit primary-btn' loading={updateLoading || loading}  >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Card>
        </Layout>
    )
}

export default WebsiteComponent