import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Tag, Space, Menu, Button, Popconfirm, Avatar, Card, notification, Dropdown } from 'antd'
import { load, remove } from '../actions'
import './index.scss'
import { ImagePresignedUrl } from '@renderer'
import Toolbar from '@toolbar'
import access from '@access'
import Permissions from '@permissions'
import { useRouteMatch } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment'
import Papa from 'papaparse'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [search, setSearch] = useState('')
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        type: 0,
        sort: 'desc',
        search: '',
    })
    const prevPagination = useRef(pagination)
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const { getData, getLoading } = useSelector(state => state.admin.personalMovements)

    useEffect(() => {
        if (prevPagination.current !== pagination) dispatch(load(pagination))
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        setTableData([...getData.data])
        return () => { }
    }, [getData])

    function handleDelete(id) {
        dispatch(remove(id)).then(res => {
            if (res.type === 'personalMovements/removeFulfilled') {
                notification.success({ message: 'Deleted', description: 'You have successfully deleted a data.' });
                dispatch(load(pagination))
            }
        })
    }

    useEffect(() => {
        if (search) {
            const delayDebounceFn = setTimeout(() => {
                setPagination(prevState => ({ ...prevState, search }));
            }, 1300)
            return () => clearTimeout(delayDebounceFn)
        } else dispatch(load({ ...pagination, search: '' }))
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    function handleView(data) {
        props.history.push(`/personal-movements/view/${data._id}`)
    }

    function typeLabel(value) {
        switch (value) {
            case 0:
                return 'All'
            case 1:
                return 'Promotion'
            case 2:
                return 'Location Transfer'
            case 3:
                return 'Salary Adjustment'
            case 4:
                return 'Regularization'
            default:
                return 'All'
        }
    }

    console.log("==================>", tableData)

    function handleDownload() {
        const exportData = tableData.map(data => {
            return {
                "Created Date": moment(data.createdAt).format('LLLL'),
                "Name": `${data.user && data.user.firstName} ${data.user && data.user.lastName}`,
                "Center Code": data.centerCode,
                "Division Code": data.divisionCode,
                "Property Code": data.propertyCode,
                "Effectivity Date": moment(data.dateFrom).format('LL'),
                "Date To": moment(data.dateTo).format('LL'),
                "Remarks": data.remarks,
                "Position (FROM)": data.fromPosition ? data.fromPosition.title : '',
                "Position (TO)": data.toPosition ? data.toPosition.title : '',
                "Project (FROM)": data.toProject ? data.toProject.title : '',
                "Project (TO)": data.fromProject ? data.fromProject.title : '',
                "Type": typeLabel(data.type),
            }
        })
        var csv = Papa.unparse(exportData);
        var unix = Date.now()
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `overtimes_${unix}.csv`);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `overtimes_${unix}.csv`);
        tempLink.click();
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={44} src={<ImagePresignedUrl src={data.user && data.user.image && data.user.image[0] && data.user.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{data.user && data.user.firstName} {data.user && data.user.lastName}</span>)
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (item, data) => {
                switch (item) {
                    case 1:
                        return <Tag color="magenta">PROMOTION</Tag>
                    case 2:
                        return <Tag color="red">LOCATION TRANSFER</Tag>
                    case 3:
                        return <Tag color="volcano">SALARY ADJUSTMENT</Tag>
                    case 4:
                        return <Tag color="orange">REGULARIZATION</Tag>
                    default:
                        break;
                }
            }
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            responsive: ['lg', 'md', 'sm'],
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <Space>
                        <Button type='primary' size='small' onClick={() => handleView(data)}  >
                            View
                        </Button>
                        {(access.check('personalMovements', 'delete') && data.status === 1) &&
                            <Button type='danger' size='small'>
                                <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                    Delete
                                </Popconfirm>
                            </Button>
                        }
                    </Space>
                )
            }
        },


    ];
    return (
        <Layout className='page' >
            <Permissions module='personalMovements' role='view' >
                <Card bordered={false}>
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title='Personnel Movements'
                                handleAdd={() => props.history.push(`${path}/create`)}
                                removeAdd={!access.check('personalMovements', 'add')}
                                handleDownload={() => handleDownload()}
                                handleSearch={(search) => setSearch(search)}
                                // handleSort={(sort) => setPagination({ ...pagination, sort })}
                                customFilter={[
                                    {
                                        component: <Dropdown key={1} className='dropdown-filter' overlay={(
                                            <Menu items={[
                                                { label: 'All', key: 'all', onClick: () => { setPagination({ ...pagination, type: undefined }) } },
                                                { label: 'PROMOTION', key: 1, onClick: () => { setPagination({ ...pagination, type: 1 }) } },
                                                { label: 'LOCATION TRANSFER', key: 2, onClick: () => { setPagination({ ...pagination, type: 2 }) } },
                                                { label: 'SALARY ADJUSTMENT', key: 3, onClick: () => { setPagination({ ...pagination, type: 3 }) } },
                                                { label: 'REGULARIZATION', key: 4, onClick: () => { setPagination({ ...pagination, type: 4 }) } },

                                            ]} />
                                        )} placement="bottom">
                                            <Button>
                                                <span className='fade'>Type </span><span className='label'>{typeLabel(pagination.type)}</span><b><DownOutlined /></b>
                                            </Button>
                                        </Dropdown>
                                    }
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Permissions>
        </Layout>
    )
}

export default List