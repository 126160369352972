import React from 'react'
import { Layout, Modal } from 'antd'

const FormPersonalMovement = (props) => {
    return (
        <Modal forceRender title={(props.selectedRow && props.selectedRow.company) || 'Add Personnel Movement'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>

            </Layout>
        </Modal>
    )
}

export default FormPersonalMovement