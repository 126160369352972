import Axios from 'axios'
import { actions } from '../reducers';
import { headers } from '../../../utilities/token'

const API_URL = process.env.REACT_APP_API_BASE_URL
export const submitLogin = (data) => {
  return dispatch => {
    dispatch(actions.postLoginData())
    return Axios.post(`${API_URL}/login`, { ...data })
      .then(function (response) {
        if (response.data.token) {
          localStorage.setItem('AMS_SESSION_TOKEN', response.data.token)
          return dispatch(actions.postLoginDataFulfilled(response.data))
        }
      }).catch(function (error) {
        return dispatch(actions.postLoginDataRejected(error))
      })
  }
}

export const loadPublicSettings = () => {
  return dispatch => {
    dispatch(actions.getWebsiteSettings())
    return Axios.get(`${API_URL}/website-public-settings`)
      .then(function (response) {
        return dispatch(actions.getWebsiteSettingsFulfilled(response.data && response.data.result))
      }).catch(function (error) {
        return dispatch(actions.getWebsiteSettingsRejected(error))
      })
  }
}


export const submitLogout = () => {
  return dispatch => {
    dispatch(actions.postLogout())
    return Axios.post(`${API_URL}/user/logout`, {}, { headers: headers })
      .then(function (response) {
        return dispatch(actions.postLogoutFulfilled())
      }).catch(function (error) {
        return dispatch(actions.postLogoutRejected(error))
      })
  }
}
