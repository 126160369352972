import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Card, notification, Space, Button, Popconfirm, Avatar } from 'antd'
import { load, remove } from '../actions'
import moment from 'moment'
import Toolbar from '@toolbar'
import { ImagePresignedUrl } from '@renderer'
import { useRouteMatch } from 'react-router-dom';
import './index.scss'
import access from '@access'
const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [search, setSearch] = useState('')
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const prevPagination = useRef(pagination)
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const { getData, getLoading } = useSelector(state => state.admin.administrators)

    useEffect(() => {
        if (prevPagination.current !== pagination) dispatch(load(pagination))
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        setTableData([...getData.data])
        return () => { }
    }, [getData])

    function handleDelete(id) {
        dispatch(remove(id)).then(res => {
            if (res.type === 'administrators/removeFulfilled') {
                notification.success({ message: 'Deleted', description: 'You have successfully deleted a data.' });
                dispatch(load(pagination))
            }
        })
    }

    useEffect(() => {
        if (search) {
            const delayDebounceFn = setTimeout(() => {
                setPagination(prevState => ({ ...prevState, search }));
            }, 1300)
            return () => clearTimeout(delayDebounceFn)
        } else dispatch(load({ ...pagination, search: '' }))
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    function handleView(data) {
        props.history.push(`/administrators/view/${data._id}`)
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={44} src={<ImagePresignedUrl src={data && data.image[0] && data.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{data && data.firstName} {data && data.lastName}</span>)
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            render: (item, data) => {
                return (
                    <div className='col-image'>
                        <span>{item && item.title}</span>
                    </div>
                )
            }
        },
        {
            title: 'Job Level',
            dataIndex: 'jobLevel',
            key: 'jobLevel',
            width: 200,
            render: (item, data) => {
                return (
                    <span>{data.position && data.position.jobLevel}</span>
                )
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item, data) => {
                return (moment(data.createdAt).format('LLLL'))
            }
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <Space>
                        <Button type='primary' size='small' onClick={() => handleView(data)}  >
                            View
                        </Button>
                        {(access.check('administrators', 'delete') && data.status === 1) &&
                            <Button type='danger' size='small'>
                                <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                    Delete
                                </Popconfirm>
                            </Button>
                        }
                    </Space>
                )
            }
        },


    ];
    return (
        <Layout className='page'>
            {/* <Permissions module='administrators' role='view' > */}
            <Card bordered={false}>
                <Row gutter={32}>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <Toolbar
                            title='Administrators'
                            handleAdd={() => props.history.push(`${path}/create`)}
                            handleSearch={(search) => setSearch(search)}
                            handleSort={(sort) => setPagination({ ...pagination, sort })}
                            handleStatus={(status) => setPagination({ ...pagination, status })}
                            statusArray={[
                                { id: 0, label: 'Active' },
                                { id: 1, label: 'Inactive' },
                            ]}
                        />
                        <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />
                        <Row style={{ marginTop: 30, width: '100%' }}>
                            <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            {/* </Permissions> */}

        </Layout>
    )
}

export default List