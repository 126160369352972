import React, { useEffect, useState } from 'react'
import { Card, Space, Button, Layout, Popconfirm, Pagination, Row, Col, Table, notification } from 'antd'
import { load, remove } from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import StatusIconDisplay from '@status-icon'
import { ImagePresignedUrl } from '@renderer'
import FormComponent from './form'
import { VIEW, NONE, ADD } from '@page-actions'
import moment from 'moment'
import Toolbar from '@toolbar'
import './index.scss'

const DocumentsComponent = () => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})
    const [modalShow, setModalShow] = useState(false)
    const dispatch = useDispatch();
    const { getData, getLoading, createSuccess, updateSuccess, removeSuccess } = useSelector(state => state.client.documents)

    useEffect(() => {
        dispatch(load(pagination))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    useEffect(() => {
        setModalShow(false)
        dispatch(load(pagination))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSuccess, createSuccess, removeSuccess])

    function handleDelete(id) {
        dispatch(remove(id)).then((res) => {
            if (res.type === 'documents/removeFulfilled') {
                notification.success({
                    message: `Deleted`,
                    description: 'You have successfully deleted data.',
                });
                dispatch(load(pagination))
            }
        })
    }

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    return (
        <Layout className='documents-page'>
            <Card bordered={false}>
                <Toolbar
                    title='Documents'
                    handleSearch={(search) => setPagination({ ...pagination, search })}
                    handleAdd={() => {
                        setModalShow(true)
                        setActionType(ADD)
                        setSelectedRow({})
                    }}
                />
                <Table columns={[
                    {
                        title: 'Type',
                        dataIndex: 'type',
                        key: 'type',
                        render: (item, data) => {
                            return <span>{data.type} <StatusIconDisplay status={data.status} /></span>
                        }
                    },
                    {
                        title: 'Expiration Date',
                        dataIndex: 'expirationDate',
                        key: 'expirationDate',
                        render: (item, data) => {
                            return <span>{data.expirationDate && moment(data.expirationDate).format('LL')}</span>
                        }
                    },
                    {
                        title: 'Images / Files',
                        dataIndex: 'images',
                        key: 'images',
                        responsive: ['lg', 'md', 'sm'],
                        render: (item, data) => {
                            return <div className='files'>
                                {
                                    data.images && data.images.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <ImagePresignedUrl src={item.url} style={{ width: 80, height: 100 }} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    },
                    {
                        title: '',
                        dataIndex: 'actions',
                        key: 'actions',
                        render: (item, data) => {
                            return (
                                <Space>
                                    <Button type='primary' size='small' onClick={() => {
                                        setModalShow(true)
                                        setActionType(VIEW)
                                        setSelectedRow(data)
                                    }}  >
                                        View
                                    </Button>
                                    {(data.status === 1) &&
                                        <Button type='danger' size='small'>
                                            <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                                Delete
                                            </Popconfirm>
                                        </Button>
                                    }
                                </Space>
                            )
                        }
                    },
                ]} dataSource={getData.data} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />
            </Card>
            <Row style={{ marginTop: 30, width: '100%' }}>
                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                </Col>
            </Row>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

export default DocumentsComponent
