import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Modal, notification } from 'antd'
import { update, create } from '../actions'
import { ADD, VIEW, NONE } from '@page-actions'
import session from '@session'
import moment from 'moment'
import './index.scss'

const FormReference = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm()
    const { updateLoading } = useSelector(state => state.client.references)

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            year: (props.selectedRow && moment(props.selectedRow.year)) || new Date(),
        })
        // eslint-disable-next-line
    }, [props.selectedRow])


    async function handleSubmit(value) {
        const submitData = { ...value, }
        if (props.actionType === ADD) {
            const userId = session.user().id
            dispatch(create({ userId, ...submitData })).then(res => {
                if (res.type === 'references/createFulfilled') {
                    props.setActionType(NONE)
                    form.resetFields()
                    notification.success({
                        message: `Added`,
                        description: 'You have successfully added information.',
                    });
                }
            })
        }

        if (props.actionType === VIEW) {
            const referenceId = props.selectedRow._id
            dispatch(update({ id: referenceId, ...submitData })).then(res => {
                if (res.type === 'references/updateFulfilled') {
                    form.resetFields()
                    notification.success({
                        message: `Added`,
                        description: 'You have successfully updated information.',
                    });
                }
            })
        }
    }

    return (
        <Modal forceRender title={(props.selectedRow && props.selectedRow.company) || 'Add Reference'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`reference`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[{ required: true, message: 'Please input first name!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[{ required: true, message: 'Please input last name!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Position"
                                name="position"
                                rules={[{ required: true, message: 'Please input position!' }]}
                            >
                                <Input />
                            </Form.Item>

                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Company"
                                name="company"
                                rules={[{ required: true, message: 'Please input company!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Address"
                                name="address"
                                rules={[{ required: true, message: 'Please input address!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Tel"
                                name="tel"
                                rules={[{ required: true, message: 'Please input tel!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={updateLoading}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}


export default FormReference