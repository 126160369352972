import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Row, Col, Button, notification, Alert, Card } from 'antd'
import { submitResetPassword, checkSession } from '../actions'
import './index.scss'
import { Link } from 'react-router-dom'
import jwt_decode from "jwt-decode";

const ResetPassword = () => {
    const [isSuccess, setIsSuccess] = useState(false)
    const dispatch = useDispatch();
    const { resetPasswordLoading, checkSessionLoading } = useSelector(state => state.resetPassword)
    const [checkSessionError, setCheckSessionError] = useState({
        expiredAt: "",
        message: "",
        name: "",
        error: false,
    })

    useEffect(() => {
        var url = new URL(window.location.href);
        var token = url.searchParams.get("token");
        dispatch(checkSession({ token: token })).then((res) => {
            if (res.type === 'resetPassword/postCheckSessionRejected') {
                const { request } = res && res.payload
                const response = request && JSON.parse(request.response)
                response.message && setCheckSessionError({ ...response.message, error: true })
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onFinish = (values) => {
        console.log("VALUE", values)
        if (values.password === values.reTypePassword) {
            var url = new URL(window.location.href);
            var token = url.searchParams.get("token");
            var decoded = jwt_decode(token)
            const submitData = {
                id: decoded.id,
                password: values.password,
                token: token
            }
            dispatch(submitResetPassword(submitData)).then(res => {
                if (res.type === 'resetPassword/postResetPasswordFulfilled') {
                    setIsSuccess(true)
                }
                if (res.type === 'resetPassword/postResetPasswordRejected') {
                    notification.warning({ message: 'Invalid!', description: `.` });
                }
            })
        } else {
            notification.warning({ message: 'Did not match!', description: `The password and re-type password did not match.` });
        }

    };

    return (
        <Fragment>
            <Row justify='center'>
                <Col lg={24} md={24} sm={24} xs={24} className='container'>
                    <div className='reset-password-container'>
                        <div className='reset-password-form'>
                            <div className='title-header'>
                                <h1>Reset Password</h1>
                            </div>
                            <Card loading={checkSessionLoading} bordered={false}>
                                {
                                    checkSessionError.error ?
                                        <Alert
                                            message={checkSessionError && checkSessionError.message && checkSessionError.message.toUpperCase()}
                                            description={"It seems that you may not be able to reset your password. An error occurred to your session."}
                                            type="error"
                                            showIcon
                                        /> :
                                        !isSuccess ? <div>
                                            <Alert
                                                message="Please match the format requests"
                                                description="Password must contain a capital letter, a special character and a digit. Password length must be minimum 6 characters."
                                                showIcon
                                                type="info"
                                            />
                                            <Form
                                                name='ResetPassword'
                                                onFinish={(e) => onFinish(e)}
                                                style={{ marginTop: 12 }}
                                            >
                                                <Form.Item
                                                    name='password'
                                                    label='Password'
                                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                                >
                                                    <Input.Password className='primary-input' />
                                                </Form.Item>

                                                <Form.Item
                                                    name='reTypePassword'
                                                    label='Re-type Password'
                                                    rules={[{ required: true, message: 'Please input your re-password!' }]}
                                                >
                                                    <Input.Password className='primary-input' />
                                                </Form.Item>

                                                <Form.Item>
                                                    <div style={{ display: 'flex' }}>
                                                        <Button type='primary' style={{ width: '100%', marginTop: 10 }} disabled={resetPasswordLoading} className='primary-btn' loading={resetPasswordLoading} htmlType='submit'>
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Form.Item>
                                            </Form>
                                        </div> : <Alert
                                            message="SUCCESS"
                                            description="You have changed your password successfully. You can now log in to your account using your new password."
                                            type="success"
                                        />
                                }
                                <div className='reset-password-reminder'>
                                    <p>Go back to Login? <Link to='/'>Click here</Link></p>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment >
    )
}

export default ResetPassword