import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Row, Col, Tag, Table, Avatar, Card, Space, Button, notification } from 'antd'
import { approveTimelog, loadTimelogs } from '../actions'
import './index.scss'
import { ImagePresignedUrl } from '@renderer'
import moment from 'moment-business-time'
import Toolbar from '@toolbar'
import Permissions from '@permissions'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const ForApproval = (props) => {
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({
        employeeNumber: '',
        startDate: "",
        endDate: "",
        // startDate: "2020-10-01 17:43:58",
        // endDate: "2027-10-30 17:44:06",
        status: 1 // FOR APPROVAL
    })
    const [employeeNumber, setEmployeeNumber] = useState('')
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const { getData, getLoading, } = useSelector((state) => state.admin.timelogs)
    const dispatch = useDispatch()

    useEffect(() => {
        if (pagination.startDate && pagination.endDate) {
            dispatch(loadTimelogs(pagination))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    useEffect(() => {
        setTableData([...getData.data])
        return () => { }
    }, [getData])

    useEffect(() => {
        if (employeeNumber) {
            const delayDebounceFn = setTimeout(() => {
                setPagination(prevState => ({ ...prevState, employeeNumber }));
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        } else {
            dispatch(loadTimelogs({ ...pagination, employeeNumber: '' }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeeNumber])

    function typeTag(type) {
        switch (type) {
            case 'IN':
                return <Tag color="green">IN</Tag>
            case 'OUT':
                return <Tag color="geekblue">OUT</Tag>
            case 'BREAK OUT':
                return <Tag color="gold">BREAK OUT</Tag>
            default:
                return;
        }
    }

    const dataSource = tableData

    const columns = [
        {
            title: 'ID',
            dataIndex: '_id',
            key: '_id'
        },
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={44} src={<ImagePresignedUrl src={data && data.employee && data.employee.image && data.employee.image[0] && data.employee.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<div>
                    <span style={{ textTransform: 'capitalize' }}>{data?.employee?.firstName} {data?.employee?.lastName} </span><p>#{data?.employee?.employeeNumber}</p>
                </div>)
            }
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            render: (item, data) => {
                return (
                    <Fragment>
                        <span style={{ fontWeight: 'bold', color: '#4A9EF7', fontSize: 14 }}>{moment(item).format('LLLL')}</span><br />
                        <span>{data && data.location}</span>
                    </Fragment>
                )
            }
        },
        {
            title: 'Data Source',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            render: (item, data) => {
                return (
                    <span>{item ? 'BIOMETRICS' : 'WEB APP'}</span>
                )
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            responsive: ['lg', 'md', 'sm'],
            render: (item) => {
                return (
                    <div>
                        {typeTag(item)}
                    </div>
                )
            }
        },
    ];


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        if (newSelectedRowKeys.length) {
            window.scrollTo(0, 0)
            const id = newSelectedRowKeys[0]

            const findUserInId = tableData.find(data => data._id === id)
            const selectedUser = findUserInId?.employee
            let filteredData = tableData.filter(data => data?.employee?._id === selectedUser?._id)
            filteredData = filteredData.filter(data => (data.type !== findUserInId?.type))
            filteredData.unshift(findUserInId)

            if (newSelectedRowKeys.length === 2) {
                const data = []
                newSelectedRowKeys.map(id => {
                    const found = tableData.find(data => data._id === id)
                    data.push(found)
                })
                // filteredData = filteredData.slice(0, 2)
                setTableData([...data])
            } else {
                setTableData([...filteredData])

            }




        } else {
            setTableData([...getData.data])
        }

    };

    function findSelectedInTable(type) {
        const mapData = []
        selectedRowKeys.map(id => {
            const found = tableData.find(data => data?._id === id)
            mapData.push(found)
        })

        const filterData = mapData.find(data => data?.type === type)
        if (filterData) {
            return filterData
        }

        return null
    }

    function handleApprove() {
        const submitData = {
            ids: selectedRowKeys.toString()
        }
        dispatch(approveTimelog({ ...submitData })).then(res => {
            console.log("res", res)
            if (res.type === 'timelogs/approveTimelogFulfilled') {
                notification.success({ message: 'Updated', description: 'You have successfully approved a data.' });
                dispatch(loadTimelogs({ ...pagination }))
                setSelectedRowKeys([])
            }
        })

    }
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
                key: 'odd',
                text: 'Select Odd Row',
                onSelect: (changeableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return false;
                        }
                        return true;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
            {
                key: 'even',
                text: 'Select Even Row',
                onSelect: (changeableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return true;
                        }
                        return false;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
        ],
    };
    return (
        <Layout>
            <Permissions module='timelogs' role='view' >
                <Row gutter={32}>

                    <Col md={24} lg={24} xs={24} sm={24}>
                        <Toolbar
                            title='FOR APPROVAL - Timelogs'
                            handleSearch={(employeeNumber) => setEmployeeNumber(employeeNumber)}
                            handleDateRange={(dateRange) => setPagination({ ...pagination, startDate: dateRange[0], endDate: dateRange[1] })}
                        />
                        <Row gutter={24}>
                            <Col md={6} lg={6} xs={24} sm={24}>
                                <Card title="TIME IN" style={{ marginBottom: 12 }}>
                                    {
                                        findSelectedInTable("IN") ?
                                            <div>
                                                <Space>
                                                    <CheckCircleOutlined style={{ fontSize: 24, marginRight: 6, color: "green" }} />
                                                    <div>
                                                        <h1 style={{ textTransform: "capitalize" }}>{findSelectedInTable("IN")?.employee?.firstName} {findSelectedInTable("IN")?.employee?.lastName} - #{findSelectedInTable("IN")?.employee?.employeeNumber}</h1>
                                                        <h1 style={{ color: "#4a9ef7" }}>{moment(findSelectedInTable("IN").time).format("LLLL")} </h1>
                                                    </div>
                                                </Space>

                                            </div>
                                            : <h1 style={{ color: "red" }}><ExclamationCircleOutlined style={{ fontSize: 24, marginRight: 6 }} />TIME IN - IS EMPTY</h1>
                                    }

                                </Card>
                                <Card title="TIME OUT" style={{ marginBottom: 12 }}>
                                    {
                                        findSelectedInTable("OUT") ?
                                            <div>
                                                <Space>
                                                    <CheckCircleOutlined style={{ fontSize: 24, marginRight: 6, color: "green" }} />
                                                    <div>
                                                        <h1 style={{ textTransform: "capitalize" }}>{findSelectedInTable("OUT")?.employee?.firstName} {findSelectedInTable("OUT")?.employee?.lastName} - #{findSelectedInTable("OUT")?.employee?.employeeNumber}</h1>
                                                        <h1 style={{ color: "#4a9ef7" }}>{moment(findSelectedInTable("OUT").time).format("LLLL")} </h1>
                                                    </div>
                                                </Space>
                                            </div>
                                            : <h1 style={{ color: "red" }}><ExclamationCircleOutlined style={{ fontSize: 24, marginRight: 6 }} />TIME OUT - IS EMPTY</h1>
                                    }
                                </Card>
                                {
                                    (findSelectedInTable("IN") && findSelectedInTable("OUT")) ?
                                        <Button type="primary" icon={<CheckCircleOutlined />} onClick={handleApprove}>APPROVE TIME IN AND OUT</Button> : null
                                }
                            </Col>
                            <Col md={18} lg={18} xs={24} sm={24}>
                                <Table columns={columns} rowSelection={rowSelection} dataSource={dataSource} style={{ overflowX: 'scroll' }} rowKey={obj => obj._id} loading={getLoading} />

                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Permissions>

        </Layout>
    )
}

export default ForApproval