import { createSlice } from "@reduxjs/toolkit";

const database = createSlice({
    name: 'database',
    initialState: {
        resetLoading: false,
        resetSuccess: false,
        resetRejected: false,
        resetData: {}
    },
    reducers: {
        reset(state, action) {
            return {
                ...state,
                resetLoading: true,
                resetSuccess: false,
                resetRejected: false
            }
        },
        resetFulfilled(state, action) {
            return {
                ...state,
                resetLoading: false,
                resetSuccess: true,
                resetData: action.payload
            }
        },
        resetRejected(state, action) {
            return {
                ...state,
                resetLoading: false,
                resetRejected: true
            }
        }
    }
});

export const actions = database.actions;

export default database.reducer;