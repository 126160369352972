import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Row, Col, Button } from 'antd'
import { loadPublicSettings, submitLogin } from '../actions'
import './index.scss'
import { Link } from 'react-router-dom'
const Login = (props) => {
    const dispatch = useDispatch();
    const { loggingIn, getWebsiteSettingsData } = useSelector(state => state.login)

    useEffect(() => {
        dispatch(loadPublicSettings())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onFinish = (values) => {
        dispatch(submitLogin(values))
    };

    const { value } = getWebsiteSettingsData
    return (
        <Fragment>
            <Row justify='center'>
                <Col lg={12} md={12} sm={24} xs={24} className='left-panel'>
                    <div className='login-container'>
                        <div className='login-form'>
                            <div className='title-header'>
                                <h1>Log in</h1>
                            </div>
                            <Form
                                name='basic'
                                onFinish={(e) => onFinish(e)}
                            >
                                <Form.Item
                                    name='email'
                                    label='Email'
                                    rules={[{ required: true, message: 'Please input your email!' }]}
                                >
                                    <Input className='primary-input' />
                                </Form.Item>

                                <Form.Item
                                    name='password'
                                    label='Password'
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password className='primary-input' />
                                </Form.Item>

                                <Form.Item>
                                    <div style={{ display: 'flex' }}>
                                        <Button type='primary' style={{ width: '100%', marginTop: 10 }} disabled={loggingIn} className='primary-btn' loading={loggingIn} htmlType='submit'>
                                            Log in
                                        </Button>
                                    </div>
                                </Form.Item>

                                <div className='login-reminder'>
                                    <p>Forgot your password? <Link to='/forgot-password'>Click here</Link></p>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className='right-panel' style={{ backgroundImage: `url(${value.login_bg_image})` }}>
                    <div className='website-name'>
                        <h1>{value.title}</h1>
                        <p>HUMAN RESOURCE MANAGEMENT PORTAL</p>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}

export default Login