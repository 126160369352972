import { useSelector } from "react-redux"
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";

const Permissions = (props) => {
    const { getByIdData } = useSelector(state => state.admin.administrators)
    const roles = Array.isArray(getByIdData.roles) ? getByIdData.roles : []
    const [isShowComponent, setIsShowComponent] = useState(false)

    useEffect(() => {
        findRole()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, roles])

    function findRole() {
        if (Array.isArray(roles)) {
            const module = roles && roles.find(data => data.title === props.module)
            if (Array.isArray(props.role)) {
                const resultBool = []
                Array.isArray(props.role) && props.role.map(data => {
                    return resultBool.push(module.roles[data])
                })
                const ifSomeAreTrue = resultBool.some(data => data === true)
                setIsShowComponent(ifSomeAreTrue)
            } else {
                if (module) setIsShowComponent(module.roles[props.role])
                else setIsShowComponent(false)
            }
        }
    }

    return isShowComponent && props.children
}

Permissions.propTypes = {
    module: PropTypes.string.isRequired,
    role: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]).isRequired
}

export default Permissions