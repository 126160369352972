import Axios from 'axios'
import { headers } from '../../../../utilities/token'
import { actions } from '../reducers';
const API_URL = process.env.REACT_APP_API_BASE_URL

export const load = () => {
    return dispatch => {
        dispatch(actions.get())
        return Axios.get(`${API_URL}/website-settings`, { headers: headers })
            .then(function (response) {
                return dispatch(actions.getFulfilled(response.data && response.data.result))
            }).catch(function (error) {
                return dispatch(actions.getRejected(error))
            })
    }
}

export const update = (data) => {
    return dispatch => {
        dispatch(actions.update())
        return Axios.post(`${API_URL}/website-setting`, { ...data }, { headers: headers })
            .then(function (response) {
                return dispatch(actions.updateFulfilled(response.data))
            }).catch(function (error) {
                return dispatch(actions.updateRejected(error))
            })
    }
}











