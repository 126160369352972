import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Card, Form, Select, notification } from 'antd'
import { loadById, update, create } from '../actions'
import { load as loadEmployees } from '../../employee/actions';
import access from '@access'
import { PAGE_VIEW, PAGE_CREATE } from '@page-actions'
import { useParams, useRouteMatch } from 'react-router-dom';
import Toolbar from '@toolbar'
import './index.scss'

const { Option } = Select
const FormReference = (props) => {
    const [form] = Form.useForm()
    const [pageAction, setPageAction] = useState(PAGE_CREATE)
    const [search, setSearch] = useState('')
    const { id } = useParams();
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const employees = useSelector(state => state.admin.employees)
    const { getByIdData, getByIdLoading, createLoading, updateLoading } = useSelector(state => state.admin.references)
    const userId = getByIdData.user && getByIdData.user._id

    useEffect(() => {
        if (path === '/references/create') setPageAction(PAGE_CREATE)
        else if (path === '/references/view/:id') {
            setPageAction(PAGE_VIEW)
            dispatch(loadById(id)).then(res => {
                form.resetFields()
                form.setFieldsValue({ ...res.payload })
            })

            if (userId) dispatch(loadEmployees({ page: 1, limit: 50, id: userId, }))
        }
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            dispatch(loadEmployees({ page: 1, limit: 50, search: search, }))
        }, 1000);
        return () => clearTimeout(timerId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    function handleSubmit(value) {

        const submitData = { ...value, }

        if (pageAction === PAGE_CREATE) {
            dispatch(create({ ...submitData })).then(res => {
                if (res.type === 'references/createFulfilled') {
                    notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                    goBack()
                }
            })
        } else if (pageAction === PAGE_VIEW) {
            dispatch(update({ id, ...submitData })).then(res => {
                if (res.type === 'references/updateFulfilled') {
                    notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                    goBack()
                }
            })
        } else {
            notification.warning({ message: 'Opppps!', description: `Can't Submit Data.` });
        }
    }

    function goBack() {
        form.resetFields()
        props.history.goBack()
    }

    const isAccessDisabled = (!access.check('references', 'edit') && (pageAction === PAGE_VIEW))
    return (
        <Card bordered={false}>
            <Toolbar
                title='References'
                handleBack={() => props.history.goBack()}
            />
            <Form
                name={`reference`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (pageAction === PAGE_CREATE) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select disabled={isAccessDisabled} placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={employees.getLoading}>
                                    {
                                        employees.getData.data.map((data, index) => {
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[{ required: true, message: 'Please input first name!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[{ required: true, message: 'Please input last name!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Position"
                            name="position"
                            rules={[{ required: true, message: 'Please input position!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Company"
                            name="company"
                            rules={[{ required: true, message: 'Please input company!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[{ required: true, message: 'Please input address!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Tel"
                            name="tel"
                            rules={[{ required: true, message: 'Please input tel!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[{ message: 'Please input remarks!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        {
                            (pageAction === PAGE_VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('references', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('references', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('references', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                {
                    ((access.check('references', 'add') && (pageAction === PAGE_CREATE)) || (access.check('references', 'edit') && (pageAction === PAGE_VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={getByIdLoading || createLoading || updateLoading} disabled={getByIdLoading || createLoading || updateLoading} >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }

            </Form>
        </Card>
    )
}

export default FormReference